import React, { Component } from 'react'
import QRCode from 'qrcode'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
    }

    this.handleInputChange = this.handleInputChange.bind(this);
   
  }

  handleInputChange(e){
    let input = e;
    this.setState({
      input_string: e
    })

    // do stuff

  }


  render(){

    return (
      <div id="id">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Name</h2>
            </div>
          </div>
          <div className="row">
            
          </div>
        </div>
      </div>
    )
  }
}

export default Sketch;
