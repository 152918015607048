import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var size = 15;

    var slices = [];

    var temp_speed = Math.random() * 10;


    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, e.w, e.h);
        
       
        ctx.font = "bold 400px sans-serif";
        ctx.lineWidth = 10;
        ctx.textAlign = "center";
        ctx.fillStyle = '#fff';
        ctx.fillText("hello", e.w / 2, e.h / 2 + 100);

        var last_i = 0;
        for(var i = 1; i < e.h; i += Math.random() * size + 1){
          slices.push({
            size: i - last_i,
            y: last_i,
            data: ctx.getImageData(0, last_i, e.w, i - last_i)
          });
          last_i = parseInt(i);
        }

      },
      (ctx, e) => {
        // loop
        
        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, e.w, e.h);

        var mouse = e.mouseX;
        mouse = 1390;

        var speed = 10 * (1 - 1390 / 1436);

        if(!speed){
          speed = temp_speed;
        } else {
          temp_speed = Math.random() * 10;
        }

        for(var i in slices){
          ctx.putImageData(slices[i].data, 50 * Math.cos(e.frameCounter / 30 + parseInt(i) / speed), slices[i].y);
        }
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
