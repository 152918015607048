import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

const config = {
  world_grid_size: 60
}

var world = {
  width: 100,
  height: 100,
  offset: {
    x: 0,
    y: 0,
    padding: 10
  }, 
  extends: {
    up: false,
    down: false,
    left: false,
    right: false
  },
  center_size: .75,
  grid: [],
  grid_changed: true,
  grid_cache: undefined
}

var key = {
  ArrowLeft: false,
  ArrowRight: false,
  ArrowUp: false,
  ArrowDown: false
}

var color_code = {
  ['1']: {
    name: 'land',
    r: 255,
    g: 255,
    b: 255
  },
  ['2']: {
    name: 'platform',
    r: 100,
    g: 100,
    b: 100
  },
  ['3']: {
    name: 'death',
    r: 255,
    g: 0,
    b: 0
  },
}

var user;

const getWorldMaterial = (p) => {
  for(var i in color_code){
    var c = color_code[i];
    if(c.r == p.r && c.g == p.g && c.b == p.b){
      return c.name;
    }
  }
}


const roundValue = (value, decimals) => {
  if(value === undefined) return value;
  if(value === null) return value;
  if(isNaN(value)) return value;
  if(value.toFixed === undefined) return value;

  value = parseFloat(value.toFixed(decimals + 1));

  var retval = Number(Math.round(value+'e'+decimals)+'e-'+decimals);

  var stringVersion = value.toFixed(decimals);
  var stringVersionPlus = value.toFixed(decimals + 1);

  return retval;
}


var tween_queue = [];


class Sketch extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){

    LoopingCanvas('platformer', 
      (ctx, e) => {

        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;

        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);

        for(var y = 0; y < world.height; y += 1){
          var new_row = [];
          for(var x = 0; x < world.width; x += 1){
            if(x === 0 || y === 0 || x === world.width - 1 || y === world.height - 1){
              new_row.push(1);
            } else {
              new_row.push(0);
            }
          }
          world.grid.push(new_row);
        }

        user = new Character({
          loc: {
            x: 2,
            y: 2
          },
          sprite: {
            w: 30,
            h: 30,
            fill: '#0f0',
            img: {
              stand: {
                up: [this.refs['bowie-stand-up']],
                down: [this.refs['bowie-stand-down']],
                left: [this.refs['bowie-stand-left']],
                right: [this.refs['bowie-stand-right']]
              },
              walk: {
                up: [
                  this.refs['bowie-walk-up-0'],
                  this.refs['bowie-walk-up-1'],
                  this.refs['bowie-walk-up-2'],
                  this.refs['bowie-walk-up-3'],
                ],
                down: [
                  this.refs['bowie-walk-down-0'],
                  this.refs['bowie-walk-down-1'],
                  this.refs['bowie-walk-down-2'],
                  this.refs['bowie-walk-down-3'],
                ],
                left: [
                  this.refs['bowie-walk-left-0'],
                  this.refs['bowie-walk-left-1'],
                  this.refs['bowie-walk-left-2'],
                  this.refs['bowie-walk-left-3'],
                ],
                right: [
                  this.refs['bowie-walk-right-0'],
                  this.refs['bowie-walk-right-1'],
                  this.refs['bowie-walk-right-2'],
                  this.refs['bowie-walk-right-3'],
                ]
              }
            }
          }
         });
      },
      (ctx, e) => {


        // draw surface collision model
        ctx.resetTransform();

        if(world.grid_changed){
          ctx.fillStyle = '#666';
          ctx.fillRect(0, 0, e.w, e.h);
        }
          
        for(var y = 0; y < world.width; y++){
           for(var x = 0; x < world.height; x++){

            var c = color_code[world.grid[y][x]];
            var lx = (x - world.offset.x) * config.world_grid_size;
            var ly = (y - world.offset.y) * config.world_grid_size;

            if(lx > e.w) world.extends.right = true;
            if(lx < 0) world.extends.left = true;

            if(ly > e.h) world.extends.down = true;
            if(ly < 0) world.extends.up = true;

            if(world.grid_changed){              
              if(c){
                ctx.fillStyle = 'rgba(' + c.r + ',' + c.g + ',' + c.b + ',1)';
                ctx.fillRect(lx, ly, config.world_grid_size, config.world_grid_size);
              }
              ctx.strokeStyle = 'rgba(255,255,255,.1)';
              ctx.strokeRect(lx, ly, config.world_grid_size, config.world_grid_size);
            }
           }
        }

        if(world.grid_changed){
          world.grid_changed = false;
          world.grid_cache = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
          console.log('cache set');
        } else {
          ctx.putImageData(world.grid_cache, 0, 0);
        } 
        
        if(key.c){
          console.log(e.func.getColorAt(e.mouseX, e.mouseY));
        }
        
        user.move();
        user.applyControls(key, e);
        user.applyPhysics(ctx, e);


        ctx.translate(e.w/2,e.h/2);
        
        ctx.strokeStyle = '#0f0';
        ctx.strokeRect(-e.w * world.center_size / 2, -e.h * world.center_size / 2, e.w * world.center_size, e.h * world.center_size);

        var arrow_size = 20;
        if(world.extends.right){
          ctx.beginPath();
          ctx.moveTo(e.w * world.center_size / 2, -arrow_size);
          ctx.lineTo(e.w * world.center_size / 2 + arrow_size, 0);
          ctx.lineTo(e.w * world.center_size / 2, arrow_size);
          ctx.stroke();
        }
        if(world.extends.left){
          ctx.beginPath();
          ctx.moveTo(-e.w * world.center_size / 2, -arrow_size);
          ctx.lineTo(-e.w * world.center_size / 2 - arrow_size, 0);
          ctx.lineTo(-e.w * world.center_size / 2, arrow_size);
          ctx.stroke();
        }
        if(world.extends.up){
          ctx.beginPath();
          ctx.moveTo(-arrow_size, -e.h * world.center_size / 2);
          ctx.lineTo(0, -e.h * world.center_size / 2 - arrow_size);
          ctx.lineTo(arrow_size, -e.h * world.center_size / 2);
          ctx.stroke();
        }
        if(world.extends.down){
          ctx.beginPath();
          ctx.moveTo(-arrow_size, e.h * world.center_size / 2);
          ctx.lineTo(0, e.h * world.center_size / 2 + arrow_size);
          ctx.lineTo(arrow_size, e.h * world.center_size / 2);
          ctx.stroke();
        }

        world.extends = {
          up: false,
          down: false,
          left: false,
          right: false
        }

        ctx.resetTransform();


        user.draw(ctx);



        // switch keys from true to hold
        var key_list = Object.keys(key);
        for(var i in key_list){
          if(key[key_list[i]] === true) key[key_list[i]] = e.frameCounter;
        }

        var key_w = 30;
        var key_h = 15; 
        var key_pad = 5;
        var key_loc_x = key_pad + key_pad;
        var key_loc_y = key_pad + key_pad*2 + key_h * 2;
        // draw keyboard
        ctx.fillStyle =  key.ArrowLeft ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x, key_loc_y - key_h, key_w, key_h);

        ctx.fillStyle =  key.ArrowDown ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w, key_loc_y - key_h, key_w, key_h);

        ctx.fillStyle =  key.ArrowUp ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w, key_loc_y - key_h - key_pad - key_h, key_w, key_h);

        ctx.fillStyle =  key.ArrowRight ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w + key_pad + key_w, key_loc_y - key_h, key_w, key_h);

        ctx.fillStyle = '#aaa';
        ctx.font = "bold 16px Courier";
        ctx.fillText("user: " + user.loc.x.toFixed(1) + "," + user.loc.y.toFixed(1), key_pad, key_pad*3 + key_h*2 + 20);

        ctx.fillText("offset: " + world.offset.x.toFixed(1) + "," + world.offset.y.toFixed(1), key_pad, key_pad*3 + key_h*2 + 40);

      }
    );

    document.getElementById('platformer').addEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').addEventListener("keyup", this.keyListenerUp, false);
    document.getElementById('platformer').focus();
  }

  componentWillUnmount(){
    document.getElementById('platformer').removeEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').removeEventListener("keyup", this.keyListenerUp, false);
  }

  keyListenerDown(e){
    console.log('DN', e.key);

    switch(e.key){
      default:
        if(!key[e.key]) key[e.key] = true;
        break;
    }
  }

  keyListenerUp(e){
    // console.log('UP', e.key);

    switch(e.key){
      default:
        key[e.key] = false;  
        break;
    }
  }


  handleMove(e){

    var el_bounds = document.getElementById('platformer').getBoundingClientRect();

    var grid_cell_x = Math.floor((e.pageX + el_bounds.left) / config.world_grid_size) + world.offset.x;
    var grid_cell_y = Math.floor((e.pageY + el_bounds.top) / config.world_grid_size) + world.offset.y;
    
    for(var tx = 0; tx < 1; tx++){
      for(var ty = 0; ty < 1; ty++){
        if(world.grid[grid_cell_y + ty] !== undefined){
          if(world.grid[grid_cell_y + ty][grid_cell_x + tx] != undefined){
            for(var i = 0; i < 10; i++){
              if(key[i + '']){
                world.grid[grid_cell_y + ty][grid_cell_x + tx] = i;
                world.grid_changed = true;
              }
            }
          }
        }
      }
    }
  }

  handleClick(e){

  }

  render(){

    return (
      <div className="sketch">
        <canvas id="platformer" tabIndex="0" autoFocus onMouseMove={this.handleMove} onClick={this.handleClick}/>
        <div style={{opacity: 0}}>

          <img ref="bowie-stand-up" src="/img/bowie_pixels/stand-up.png"/>
          <img ref="bowie-stand-down" src="/img/bowie_pixels/stand-down.png"/>
          <img ref="bowie-stand-left" src="/img/bowie_pixels/stand-left.png"/>
          <img ref="bowie-stand-right" src="/img/bowie_pixels/stand-right.png"/>

          <img ref="bowie-walk-up-0" src="/img/bowie_pixels/walk-up-0.png"/>
          <img ref="bowie-walk-up-1" src="/img/bowie_pixels/walk-up-1.png"/>
          <img ref="bowie-walk-up-2" src="/img/bowie_pixels/walk-up-2.png"/>
          <img ref="bowie-walk-up-3" src="/img/bowie_pixels/walk-up-3.png"/>

          <img ref="bowie-walk-down-0" src="/img/bowie_pixels/walk-down-0.png"/>
          <img ref="bowie-walk-down-1" src="/img/bowie_pixels/walk-down-1.png"/>
          <img ref="bowie-walk-down-2" src="/img/bowie_pixels/walk-down-2.png"/>
          <img ref="bowie-walk-down-3" src="/img/bowie_pixels/walk-down-3.png"/>

          <img ref="bowie-walk-left-0" src="/img/bowie_pixels/walk-left-0.png"/>
          <img ref="bowie-walk-left-1" src="/img/bowie_pixels/walk-left-1.png"/>
          <img ref="bowie-walk-left-2" src="/img/bowie_pixels/walk-left-2.png"/>
          <img ref="bowie-walk-left-3" src="/img/bowie_pixels/walk-left-3.png"/>

          <img ref="bowie-walk-right-0" src="/img/bowie_pixels/walk-right-0.png"/>
          <img ref="bowie-walk-right-1" src="/img/bowie_pixels/walk-right-1.png"/>
          <img ref="bowie-walk-right-2" src="/img/bowie_pixels/walk-right-2.png"/>
          <img ref="bowie-walk-right-3" src="/img/bowie_pixels/walk-right-3.png"/>
          
        </div>
      </div>
    )
  }
}


class Character{

  constructor(args){
    if(!args) args = {};

    this.loc = args.loc || {
      x: 0,
      y: 0
    }

    this.loc.tx = this.loc.x;
    this.loc.ty = this.loc.y;
    this.loc.ax = this.loc.x;
    this.loc.ay = this.loc.y;
    this.loc.tween_x = this.loc.x;
    this.loc.tween_y = this.loc.y;

    this.v = args.v || {
      x: 0,
      y: 0,
      auto_direction: 1
    }

    this.inventory = [];

    this.sprite = args.sprite || {
      w: 5,
      h: 25
    }

    this.sprite.mode = 'stand';
    this.sprite.direction = 'down';
    this.sprite.frame = 0;

    this.framesPerStep = {
      walk: 12,
      sniffing_walk: 48,
      run: 4,
      jump: 30,
      sniff: 4,
      attack: 15,
      dig: 90
    }

    this.tween_list = [];

    this.movingLockedFrames = 0;
  }

  addToTweenList(variable, value, frames){
    this.tween_list.push({
      variable: variable,
      value: value,
      frames: frames,
      frames_left: frames
    })
  }

  move(){
    // console.log(this.tween_list.length);
    for(var i = 0; i < this.tween_list.length; i++){
      var t = this.tween_list[i];
      this.loc[t.variable] += t.value / t.frames;
      this.loc[t.variable] = roundValue(this.loc[t.variable], 3);

      t.frames_left--;
      if(t.frames_left == 0){
        this.loc[t.variable] = roundValue(this.loc[t.variable], 0);
        this.tween_list.splice(i,1);
        i--;
      }
    }

    if(this.tween_list.length === 0){
      this.loc.x = this.loc.ax;
      this.loc.y = this.loc.ay;
      this.loc.tx = this.loc.ax;
      this.loc.ty = this.loc.ay;
    }

    this.movingLockedFrames--;
    if(this.movingLockedFrames < 0) this.movingLockedFrames = 0;
  }

  die(){
    this.loc.x = 20;
    this.loc.y = 50;
  }

  applyPhysics(ctx, e){
    if(this.loc.tx !== this.loc.x || this.loc.ty != this.loc.y){

      var p = e.func.getColorAt(
        (this.loc.tx - world.offset.x + .5) * config.world_grid_size, 
        (this.loc.ty - world.offset.y + .5) * config.world_grid_size
      );

      this.canMove = true;
      
      if(getWorldMaterial(p) === 'land'){
        this.canMove = false;
        this.movingLockedFrames = 0;
      }


      // ctx.fillStyle = this.canMove ? '#0f0' : '#f00';
      // ctx.fillRect( 
      //   (this.loc.tx - world.offset.x) * config.world_grid_size, 
      //   (this.loc.ty - world.offset.y) * config.world_grid_size,
      //   config.world_grid_size,
      //   config.world_grid_size
      // )


      if(this.canMove){
        if(world.extends.left && (this.loc.tx - world.offset.x + .5) * config.world_grid_size < e.w / 2 - world.center_size * e.w /2){
          console.log('too left');
          world.offset.x--;
          world.grid_changed = true;
          this.loc.x = this.loc.tx;
          this.loc.ax = this.loc.tx;
        } else if(world.extends.right && (this.loc.tx - world.offset.x + .5) * config.world_grid_size > e.w / 2 + world.center_size * e.w /2){
          console.log('too right');
          world.offset.x++;
          world.grid_changed = true;
          this.loc.x = this.loc.tx;
          this.loc.ax = this.loc.tx;
        } 
        if(this.loc.tx != this.loc.ax){
          var framesPerStep = this.framesPerStep[this.sprite.mode];
                
          // if(this.action){
          //   var a = this.actionDictionary[this.action];
          //   if(a.type === 'multiply'){
          //     framesPerStep *= 1/a.value;
          //   } else if(a.type === 'stall'){
          //     framesPerStep = a.value;
          //   }
          // }
          // console.log(framesPerStep);
          this.addToTweenList('x', this.loc.tx - this.loc.ax, framesPerStep);
          this.loc.ax = this.loc.tx;
        }

        if(world.extends.up && (this.loc.ty - world.offset.y + .5) * config.world_grid_size < e.h / 2 - world.center_size * e.h /2){
          console.log('too up');
          world.offset.y--;
          world.grid_changed = true;
          this.loc.y = this.loc.ty;
          this.loc.ay = this.loc.ty;
        } else if(world.extends.down && (this.loc.ty - world.offset.y + .5) * config.world_grid_size > e.h / 2 + world.center_size * e.h /2){
          console.log('too down');
          world.offset.y++;
          world.grid_changed = true;
          this.loc.y = this.loc.ty;
          this.loc.ay = this.loc.ty;
        }

        if(this.loc.ty != this.loc.ay){
          var framesPerStep = this.framesPerStep[this.sprite.mode];
                
          // if(this.action){
          //   var a = this.actionDictionary[this.action];
          //   if(a.type === 'multiply'){
          //     framesPerStep *= 1/a.value;
          //   } else if(a.type === 'stall'){
          //     framesPerStep = a.value;
          //   }
          // }
          this.addToTweenList('y', this.loc.ty - this.loc.ay, this.framesPerStep[this.sprite.mode]);
          this.loc.ay = this.loc.ty;
        }
      } else {
        this.loc.tx = this.loc.ax;
        this.loc.ty = this.loc.ay;
      }
    }
  }

  applyControls(key, e){
    if(this.movingLockedFrames > 0) return;

    var frames;
    this.sprite.mode = 'stand';
    if(key.ArrowUp || key.ArrowDown || key.ArrowRight || key.ArrowLeft){
      frames = this.framesPerStep.walk;
      
      // if(this.action){
      //   var a = this.actionDictionary[this.action];
      //   if(a.type === 'multiply'){
      //     frames *= 1/a.value;
      //   } else if(a.type === 'stall'){
      //     frames = a.value;
      //   }
      // }

      this.sprite.mode = 'walk';

      if(key.s){
        this.sprite.mode = 'sniffing_walk';
        frames = this.framesPerStep.sniffing_walk;
      } else if(key.Shift){
        this.sprite.mode = 'run';
        frames = this.framesPerStep.run;
      }
    } else {
      this.sprite.frame = 0;  
    }

    // console.log(frames, );

    var local_key = {
      ArrowUp: key.ArrowUp,
      ArrowDown: key.ArrowDown,
      ArrowLeft: key.ArrowLeft,
      ArrowRight: key.ArrowRight,
      d: key.d,
      // s: key.s,
      a: key.a,
      [' ']: key[' ']
    }

    var trueKey = false;
    var recentKeyValue = Number.MIN_VALUE;
    var recentKey;
    for(var i in local_key){
      if(local_key[i] === true){
        trueKey = i;
      }

      if(local_key[i] > recentKeyValue){
        recentKeyValue = local_key[i];
        recentKey = i;
      }
    }

    if(trueKey){
      for(var i in local_key){
        if(i !== trueKey){
          local_key[i] = false;
        }
      }
    } else {
      for(var i in local_key){
        if(i !== recentKey){
          local_key[i] = false;
        }
      }
    }

    var arrowKeyFrame = false;
    if(local_key.ArrowUp === true || (local_key.ArrowUp && (e.frameCounter - local_key.ArrowUp) % frames === 0)){
      this.loc.ty--;
      arrowKeyFrame = true;
    } 
    if(local_key.ArrowUp){
      this.current_sprite = this.sprite.img.walk.up;
      this.sprite.direction = 'up';
      this.sprite.frame = (e.frameCounter - local_key.ArrowUp);
    }

    if(local_key.ArrowDown === true || (local_key.ArrowDown && (e.frameCounter - local_key.ArrowDown) % frames === 0)){
      this.loc.ty++;
      arrowKeyFrame = true;
    }
    if(local_key.ArrowDown){
      this.current_sprite = this.sprite.img.stand.down;
      this.sprite.direction = 'down';
      this.sprite.frame = (e.frameCounter - local_key.ArrowDown);
    }

    if(local_key.ArrowLeft === true || (local_key.ArrowLeft && (e.frameCounter - local_key.ArrowLeft) % frames === 0)){
      this.loc.tx--;
      arrowKeyFrame = true;
    }
    if(local_key.ArrowLeft){
      this.current_sprite = this.sprite.img.stand.left;
      this.sprite.direction = 'left';
      this.sprite.frame = (e.frameCounter - local_key.ArrowLeft);
    }

    if(local_key.ArrowRight === true || (local_key.ArrowRight && (e.frameCounter - local_key.ArrowRight) % frames === 0)){
      this.loc.tx++;
      arrowKeyFrame = true;
    }
    if(local_key.ArrowRight){
      this.current_sprite = this.sprite.img.stand.right;
      this.sprite.direction = 'right';
      this.sprite.frame = (e.frameCounter - local_key.ArrowRight);
    }

    if(local_key[' '] === true || (local_key[' '] && (e.frameCounter - local_key[' ']) % 30 === 0)){
      this.movingLockedFrames = 30;
      this.sprite.mode = 'jump';
      var jump_distance = arrowKeyFrame ? 1 : 2;

      switch(this.sprite.direction){
        case 'up':
          this.loc.ty -= jump_distance;
          break;
        case 'down':
          this.loc.ty += jump_distance;
          break;
        case 'left':
          this.loc.tx -= jump_distance;
          break;
        case 'right':
          this.loc.tx += jump_distance;
          break;
      }
    }

    this.action = false;

    if(local_key.d === true || (local_key.d && (e.frameCounter - local_key.d) % this.framesPerStep.dig === 0)){
      console.log('dig');
      this.movingLockedFrames = this.framesPerStep.dig;
      this.action = 'dig';
    }
    if(local_key.a === true || (local_key.a && (e.frameCounter - local_key.a) % this.framesPerStep.attack === 0)){
      console.log('attack');
      this.movingLockedFrames = this.framesPerStep.attack;
      this.action = 'attack';
    }
    // if(key.s){
    //   console.log('sniff');
    //   // this.movingLockedFrames = this.framesPerStep.sniff;
    //   this.action = 'sniff';
    // }
  }

  draw(ctx){

    // var mode = this.sprite.mode + '';
    // if(mode != 'jump') mode = 'walk';
    var mode = 'walk';

    var sprite_frame = parseInt(this.sprite.frame * 4/30) % this.sprite.img[mode][this.sprite.direction].length;
  
    this.current_sprite = this.sprite.img[mode][this.sprite.direction][sprite_frame];

    ctx.strokeStyle = this.sprite.fill;
    ctx.strokeRect((this.loc.ax - world.offset.x) * config.world_grid_size, (this.loc.ay - world.offset.y) * config.world_grid_size, config.world_grid_size, config.world_grid_size);
    ctx.drawImage(this.current_sprite, (this.loc.x - world.offset.x) * config.world_grid_size, (this.loc.y - world.offset.y) * config.world_grid_size, config.world_grid_size, config.world_grid_size);
  }
}

export default Sketch;



