import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';


class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    var interval = 10;
    var lastInterval = 0;

    var start = {
      x: window.innerWidth/2,
      y: window.innerHeight/2
    }
    var end = {
      x: window.innerWidth/2,
      y: window.innerHeight/2
    }

    var numPoints = 10;
    var points = [];
    var velocities = [];

    for(var i = 0; i < numPoints; i++){
      // points.unshift(JSON.parse(JSON.stringify(start)));
      points.push(JSON.parse(JSON.stringify(end)));
      velocities.push({
        x: (Math.random() - .5) * 5,
        y: (Math.random() - .5) * 5
      });
    }

    var spring_points = JSON.parse(JSON.stringify(points));
    var spring = .5;

    var toggle = 1;
    var toggleCount = 0;

    LoopingCanvas('scribble-screen-saver', (ctx, e) => {
      // ctx.clearRect(0, 0, e.w, e.h);
      ctx.fillStyle = 'rgba(0,0,0,.05)';
      // ctx.fillStyle = 'rgba(30,150,255,.01)';
      ctx.fillRect(0,0,e.w,e.h);


      var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.putImageData(imageData, Math.cos(e.frameCounter/100) * 10, Math.sin(e.frameCounter/100) * 10);
     
      // if(e.frameCounter - lastInterval > interval){
        // lastInterval = e.frameCounter;

        // points[parseInt(Math.random()*points.length)].x += Math.random() * 5 - 2.5;
        // points[parseInt(Math.random()*points.length)].y += Math.random() * 5 - 2.5;

        // spring_points.splice(parseInt(spring_points.length / 2),0, spring_points[parseInt(spring_points.length / 2)])
      // }

      // var movement = 

      for(var i in points){
        points[i].x += velocities[i].x;
        points[i].y += velocities[i].y;

        if(points[i].x < 0){
          velocities[i].x *= -1;
          points[i].x = 0;
        }
        if(points[i].x > e.w){
          velocities[i].x *= -1;
          points[i].x = e.w;
        }
        if(points[i].y < 0){
          velocities[i].y *= -1;
          points[i].y = 0;
        }
        if(points[i].y > e.h){
          velocities[i].y *= -1;
          points[i].y = e.h;
        }

        spring_points[i].x = spring * spring_points[i].x + (1 - spring) * points[i].x;
        spring_points[i].y = spring * spring_points[i].y + (1 - spring) * points[i].y;
      }


      function drawCurvedLine(points){
        var points = JSON.parse(JSON.stringify(points));
        var next_loc;
        ctx.beginPath();
        ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
        for(var i = 1; i < points.length; i++){
          next_loc = points[i + 1];
          if(!next_loc) continue;

          var xc = (points[i].x + next_loc.x) / 2;
          var yc = (points[i].y + next_loc.y) / 2;
          ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
        }
      }

      function drawCurvedLoop(points){
        var points = JSON.parse(JSON.stringify(points));
        var next_loc;
        ctx.beginPath();
        ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
        for(i = 1; i < points.length; i++){
          next_loc = points[i + 1];
          if(!next_loc) next_loc = points[0];

          var xc = (points[i].x + next_loc.x) / 2;
          var yc = (points[i].y + next_loc.y) / 2;
          ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
        }

        var xc = (points[0].x + points[1].x) / 2;
        var yc = (points[0].y + points[1].y) / 2;
        ctx.quadraticCurveTo(points[0].x, points[0].y, xc, yc);
      }

      

      ctx.lineWidth = 2;
      ctx.lineCap = 'round';
      ctx.strokeStyle = '#fff';
      // ctx.fillStyle = '#000'
      drawCurvedLoop(spring_points);
      ctx.stroke();
      // ctx.fill();

      ctx.lineWidth = 1;
      
      // for(var i in spring_points){
      //   ctx.beginPath();
      //   ctx.arc(spring_points[i].x, spring_points[i].y, 3, 0, 2 * Math.PI);
      //   ctx.fillStyle = '#fff';
      //   ctx.fill();
      // }



    })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="scribble-screen-saver" key="scribble-screen-saver"/>
      </div>
    )
  }
}

export default Sketch;
