import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){


    var loc = [];
    var spring_loc = [];
    var spring = [];
    var frames_between = 30;
    var times_rotated = 2;
    var last_change = -frames_between;
    var radius = 200;
    var theta = 22.5;
    var rotate_theta = 22.5;

    LoopingCanvas('debut', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
      },
      (ctx, e) => {
        // loop
        ctx.resetTransform();
        

        ctx.fillStyle = 'rgba(255,255,255,.5)';
        ctx.fillRect(0, 0, e.w, e.h);

        
        ctx.translate(e.w/2, e.h/2);
        
        if(e.frameCounter - last_change > frames_between){
          loc = [];
          spring = [];

          times_rotated++;

          if((times_rotated - 3) % 16 == 0 || times_rotated - 3 == 0){
            radius = 125;
            console.log('looped');
          } else {
            radius = 50 + Math.random() * 100;
          }
          
          
          last_change = e.frameCounter;

          for(var i = times_rotated * rotate_theta; i < 360 + times_rotated * rotate_theta; i+= theta){
            if((times_rotated - 3) % 16 == 0){
              spring.push(.2);
            } else {
              spring.push(Math.random() * .3);
            }
            // spring.push(.1);
            loc.push({
              x: radius * Math.cos(i * Math.PI/180),
              y: radius * Math.sin(i * Math.PI/180)
            })
          }
          // console.log('rotate');
        }

        if(loc.length === 0) return;

        if(spring_loc.length == 0) spring_loc = loc;

        for(var i in loc){
          spring_loc[i].x = spring[i] * loc[i].x + (1 - spring[i]) * spring_loc[i].x;
          spring_loc[i].y = spring[i] * loc[i].y + (1 - spring[i]) * spring_loc[i].y;
        
        }

        function drawCurvedLoop(points){
          var points = JSON.parse(JSON.stringify(points));
          var next_loc;
          ctx.beginPath();
          ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
          for(i = 1; i < points.length; i++){
            next_loc = points[i + 1];
            if(!next_loc) next_loc = points[0];

            var xc = (points[i].x + next_loc.x) / 2;
            var yc = (points[i].y + next_loc.y) / 2;
            ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
          }

          var xc = (points[0].x + points[1].x) / 2;
          var yc = (points[0].y + points[1].y) / 2;
          ctx.quadraticCurveTo(points[0].x, points[0].y, xc, yc);
          ctx.stroke();
          ctx.fill();
        }


        function drawCurvedLine(points){
          var points = JSON.parse(JSON.stringify(points));
          var next_loc;
          ctx.beginPath();
          ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
          for(i = 1; i < points.length; i++){
            next_loc = points[i + 1];
            if(!next_loc) continue;

            var xc = (points[i].x + next_loc.x) / 2;
            var yc = (points[i].y + next_loc.y) / 2;
            ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
          }

          ctx.stroke();
        }


        var flattened = [];
        for(var i in spring_loc){
          flattened.push({
            x: spring_loc[i].x,
            y: spring_loc[i].y / 10 + 175
          })
        }

        ctx.lineWidth = 10;
        ctx.fillStyle = 'rgba(200,200,200,1)';
        ctx.strokeStyle ='rgba(200,200,200,1)';
        drawCurvedLoop(flattened);

        ctx.lineWidth = 45;
        ctx.lineCap = "round";
        ctx.fillStyle = '#ea4c89';
        ctx.strokeStyle = 'rgba(196,61,97,1)';
        drawCurvedLoop(spring_loc);

        ctx.lineWidth = 22;
        drawCurvedLine([
          spring_loc[0],
          spring_loc[0], 
          {
            x: (spring_loc[0].x*.25 + spring_loc[Math.floor(spring_loc.length/2)].x*.25 + spring_loc[Math.floor(spring_loc.length/4)].x*.15 + spring_loc[Math.floor(spring_loc.length * 3/4)].x*.45),
            y: (spring_loc[0].y + spring_loc[Math.floor(spring_loc.length/2)].y + spring_loc[Math.floor(spring_loc.length/4)].y + spring_loc[Math.floor(spring_loc.length * 3/4)].y) / 4,
          },
          spring_loc[Math.floor(spring_loc.length/2)],
          spring_loc[Math.floor(spring_loc.length/2)]
         ]);


        drawCurvedLine([
          spring_loc[3],
          spring_loc[3],
          {
            x: (spring_loc[0].x*.55 + spring_loc[Math.floor(spring_loc.length/2)].x * .45),
            y: (spring_loc[0].y*.55 + spring_loc[Math.floor(spring_loc.length/2)].y * .45),
          },
          spring_loc[13],
          spring_loc[13],
         ]);



        drawCurvedLine([
          spring_loc[5],
          spring_loc[5],
          {
            x: (spring_loc[0].x*.45 + spring_loc[Math.floor(spring_loc.length/2)].x * .55),
            y: (spring_loc[0].y*.45 + spring_loc[Math.floor(spring_loc.length/2)].y * .55),
          },
          spring_loc[11],
          spring_loc[11],
         ]);

      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="debut" key="debut"/>
      </div>
    )
  }
}

export default Sketch;
