import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';

import './WorkManager.css';

var work = require('../../work/_list.js').default;
var Work;

class WorkManager extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.loadWork = this.loadWork.bind(this);
  }

  componentWillMount(){
    this.loadWork(this.props);
  }

  loadWork(props){
    const { dispatch } = this.props;

    
    var work_id = props.work;

    var found = false;
    for(var i in work){
      if(work[i].id === work_id){
        found = true;
        break;
      }
    }

    if(!found && work_id != 'random' && work_id != 'latest' && work_id){
      try{
        Work = require('../../work/' + work_id + '.js').default;
      } catch(e){
        console.error(e);
        Work = require('../../work/' + work[0].id + '.js').default;
        return dispatch(push('/work')); 
      }
    } 

    try{
      Work = require('../../work/' + work_id + '.js').default;
    } catch(e){
      console.error(e);
      Work = require('../../work/' + work[0].id + '.js').default;
      return dispatch(push('/work')); 
    }
  }

  componentWillReceiveProps(newprops){
    this.loadWork(newprops);
  }

  render(){

    return (
      <div className="work-manager">
        <Work/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // const { user } = state;
  return {
    // user
  }
}

export default connect(mapStateToProps)(WorkManager);
