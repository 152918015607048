import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

var loc = {
  x: 0,
  y: 0
}

var key = {
  left: false,
  right: false,
  up: false,
  down: false
}

var v = {
  x: 0,
  y: 0
}

var sprite = {
  w: 5,
  h: 25
}

var jump_velocity_minimum = 3;
var jump_velocity_continued = 1;
var jump_height_counter = 0;
var jump_affected_frames = 7;
var landed_speed = 1;
var air_speed = .4;
var gravity = .6;
var max_vy = 8;

var landed = false;
var air_friction = .9;
var landed_friction = .7;
var collision_friction = .2;

var world_grid_size = 5;

var grid = [];

class Sketch extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){

    LoopingCanvas('platformer', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);

        loc.x = e.w/2;
        loc.y = 0;

        for(var y = 0; y < e.h; y += world_grid_size){
          var new_row = [];
          for(var x = 0; x < e.w; x += world_grid_size){
            if(y >= e.h - world_grid_size * 3){
              new_row.push(1);
            } else {
              new_row.push(0);
            }
          }
          grid.push(new_row);
        }
        
      },
      (ctx, e) => {


        loc.y += v.y;
        loc.x += v.x;

        if(landed){
          // landed = true;
          if(key.left){
            v.x -= landed_speed;
          }
          if(key.right){
            v.x += landed_speed;
          }
        } else {
          // landed = false;

          if(key.left){
            v.x -= air_speed;
          }
          if(key.right){
            v.x += air_speed;
          }
        }

        if(jump_height_counter < jump_affected_frames && key.up){
          if(jump_height_counter === 0){
            v.y = -jump_velocity_minimum;  
          } else {
            v.y += -jump_velocity_continued;
          }
          jump_height_counter++;
        }


        // clear screen
        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, e.w, e.h);

        // draw surface collision model
        ctx.fillStyle = '#f00';
        for(var y in grid){
          for(var x in grid[y]){
            // ctx.fillStyle = grid[y][x] ? '#fff' : '#000';
            if(grid[y][x]){
              ctx.fillRect(x * world_grid_size, y * world_grid_size, world_grid_size, world_grid_size);
            }
          }
        }

        // check running into left surface
        var left_collision_pattern = '';
        var left_hit = false;
        for(var i = loc.y; i < loc.y + sprite.h; i += sprite.h / 10){
          var p = e.func.getColorAt(loc.x, parseInt(i));
          if(p.r === 255 && p.g === 0 && p.b === 0){
            left_collision_pattern += '1';
          } else {
            left_collision_pattern += 0;
          }
        }

        if(left_collision_pattern.match(/^10+$/g)){
          // console.log('ceiling only');
          // no hit, just collided with the ceiling
        } else if(left_collision_pattern.match(/^0*1{5,}$/g)){
          // hit and blocked
          left_hit = true;
          // console.log('lower collision');
        } else if(left_collision_pattern.match(/^0+1+0+$/g)){
          // hit and blocked
          left_hit = true; 
          // console.log('mid collision');
        } else if(left_collision_pattern.match(/^1+0+$/g)){
          // hit and blocked
          left_hit = true; 
          // console.log('upper collision');
        }

        // check running into right surface
        var right_collision_pattern = '';
        var right_hit = false;
        for(var i = loc.y; i < loc.y + sprite.h; i += sprite.h / 10){
          var p = e.func.getColorAt(loc.x + sprite.w, parseInt(i));
          if(p.r === 255 && p.g === 0 && p.b === 0){
            right_collision_pattern += '1';
          } else {
            right_collision_pattern += 0;
          }
        }

        if(right_collision_pattern.match(/^10+$/g)){
          // console.log('ceiling only');
          // no hit, just collided with the ceiling
        } else if(right_collision_pattern.match(/^0*1{5,}$/g)){
          // hit and blocked
          right_hit = true;
          // console.log('lower collision');
        } else if(right_collision_pattern.match(/^0+1+0+$/g)){
          // hit and blocked
          right_hit = true; 
          // console.log('mid collision');
        } else if(right_collision_pattern.match(/^1+0+$/g)){
          // hit and blocked
          right_hit = true; 
          // console.log('upper collision');
        }


        if(left_hit && right_hit){
          // this is likely a surface above or below
        } else if(left_hit){
          if(v.x < 0){
            loc.x -= v.x * collision_friction;
            v.x = -v.x * collision_friction;
            console.log('left collision');
            console.log(left_collision_pattern);
          }
        } else if(right_hit){
          if(v.x > 0){
            loc.x -= v.x * collision_friction;
            v.x = -v.x * collision_friction;
            console.log('right collision');
            console.log(right_collision_pattern);
          }
        }

        // check jumping up into a surface
        var boundary_to_check = loc.y;
        var p = e.func.getColorAt(loc.x + sprite.w/2, loc.y);
        
        while(p.r === 255 && p.g === 0 && p.b === 0){
          p = e.func.getColorAt(loc.x + sprite.w/2, boundary_to_check);
          if(p.r === 255 && p.g === 0 && p.b === 0){
            loc.y = Math.ceil((boundary_to_check) / world_grid_size) * world_grid_size;
            v.y = 0;
            boundary_to_check += world_grid_size;
          } else {
            break;
          }
        } 


        var p = e.func.getColorAt(loc.x + sprite.w/2, loc.y + sprite.h);
        var boundary_to_check = loc.y + sprite.h;
        landed = false;
        while(p.r === 255 && p.g === 0 && p.b === 0){
          
          p = e.func.getColorAt(loc.x + sprite.w/2, boundary_to_check);
          if(p.r === 255 && p.g === 0 && p.b === 0){
            loc.y = Math.floor((boundary_to_check) / world_grid_size) * world_grid_size - sprite.h;
            landed = true;
            boundary_to_check -= world_grid_size;
          } else {
            break;
          }
        } 

        if(landed){
          v.x *= landed_friction;
          if(v.y > 0){
            v.y = 0;
          }
        } else {
          v.x *= air_friction;
          v.y += gravity;

          if(v.y > max_vy) v.y = max_vy;
        }

        // get height above next item
        var found_below = false;
        var height_above = 0;
        while(!found_below){
          var p = e.func.getColorAt(loc.x + sprite.w/2, loc.y + sprite.h + height_above);

          if(p.r === 255 && p.g === 0 && p.b === 0){
            found_below = true;
          }
          
          if(found_below){
            break;
          }
            
          height_above++;
          if(height_above > e.h) break;
        }
        
        if(height_above < world_grid_size ) landed = true;
        console.log(height_above);


        ctx.fillStyle = '#fff';
        ctx.beginPath()
        ctx.moveTo(loc.x, loc.y + sprite.h);
        ctx.lineTo(loc.x + v.x * 2, loc.y);
        ctx.lineTo(loc.x + v.x * 2 + sprite.w, loc.y);
        ctx.lineTo(loc.x + sprite.w, loc.y + sprite.h);
        ctx.fill();


        var key_w = 30;
        var key_h = 15; 
        var key_pad = 5;
        var key_loc_x = key_pad + key_pad;
        var key_loc_y = key_pad + key_pad*2 + key_h * 2;
        // draw keyboard
        ctx.fillStyle =  key.left ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x, key_loc_y - key_h, key_w, key_h);

        ctx.fillStyle =  key.down ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w, key_loc_y - key_h, key_w, key_h);

        ctx.fillStyle =  key.up ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w, key_loc_y - key_h - key_pad - key_h, key_w, key_h);


        ctx.fillStyle =  key.right ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w + key_pad + key_w, key_loc_y - key_h, key_w, key_h);
      }
     )

    document.getElementById('platformer').addEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').addEventListener("keyup", this.keyListenerUp, false);
    document.getElementById('platformer').focus();
  }

  componentWillUnmount(){
    document.getElementById('platformer').removeEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').removeEventListener("keyup", this.keyListenerUp, false);
  }

  keyListenerDown(e){
    // console.log(e);

    switch(e.key){
      case 'ArrowUp':
        key.up = true;
        if(landed){
          console.log('trigger jump');
          jump_height_counter = 0;
        } else {
          console.log('not yet landed');
        }
        e.preventDefault();
        break;
      case 'ArrowDown':
        key.down = true;
        e.preventDefault();
        break;
      case 'ArrowLeft':
        key.left = true;
        break;
      case 'ArrowRight':
        key.right = true;
        break;


      case 'p':
        key.p = true;
        break;

      case 'e':
        key.e = true;
        break;
    }
  }

  keyListenerUp(e){
    // console.log(e);

    switch(e.key){
      case 'ArrowUp':
        key.up = false;
        jump_height_counter = jump_affected_frames;
        break;
      case 'ArrowDown':
        key.down = false;
        break;
      case 'ArrowLeft':
        key.left = false;
        break;
      case 'ArrowRight':
        key.right = false;
        break;

      case 'p':
        key.p = false;
        break;

      case 'e':
        key.e = false;
        break;
    }
  }


  handleMove(e){

    var el_bounds = document.getElementById('platformer').getBoundingClientRect();

    var grid_cell_x = Math.floor((e.pageX + el_bounds.left) / world_grid_size);
    var grid_cell_y = Math.floor((e.pageY + el_bounds.top) / world_grid_size); // - 100 idk why

    
    for(var tx = -1; tx < 2; tx++){
      for(var ty = -1; ty < 2; ty++){
        if(grid[grid_cell_y + ty] !== undefined){
          if(grid[grid_cell_y + ty][grid_cell_x + tx] != undefined){
            if(key.p){
              grid[grid_cell_y + ty][grid_cell_x + tx] = 1;
            }

            if(key.e){
              grid[grid_cell_y + ty][grid_cell_x + tx] = 0;
            }
          }
        }
      }
    }
  }

  handleClick(e){
    var grid_cell_x = Math.floor(e.pageX / world_grid_size);
    var grid_cell_y = Math.floor((e.pageY) / world_grid_size); // - 100 idk why

    if(grid[grid_cell_y][grid_cell_x] === 0){
      grid[grid_cell_y][grid_cell_x] = 1;
    } else {
      grid[grid_cell_y][grid_cell_x] = 0;
    }
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="platformer" tabIndex="0" autoFocus onMouseMove={this.handleMove} onClick={this.handleClick}/>
      </div>
    )
  }
}

export default Sketch;



