import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'


class Audio extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;



    return <div className="body-stretch">
      <Header/>

      <div className="container sketch-list">
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-300">audio</h2>
            <p>
              To get away from computers and screens, I've been working on organizing a few modular synthesizers. My current focus is on taking a short audio sample and radically transforming it in multiple ways. 
            </p>
            <p>
              Below are a few of my recent experiments, where all of the audio comes from a single initial sample for each recording - typically some acoustic guitar played into a stereo mic.
            </p>
            <p>
              Headphones recommended.
            </p>
          </div>
        </div>
        <hr/>
        
        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/OxcCbBHQNN4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Jan 7, 2023</p>
          </div>
        </div>
        
        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/MBxoPs-gy44" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Jan 6, 2023</p>
          </div>
        </div>
        
        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/wH-i8w0BBws" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Dec 24, 2022</p>
          </div>
        </div>
        
        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/IKDLrIAveMI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Dec 22, 2022</p>
          </div>
        </div>

        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/qh4xhIpjDKk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Oct 5, 2022</p>
          </div>
        </div>
        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/saZPcurcpoc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Oct 3, 2022</p>
          </div>
        </div>
        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/hFt8dTpJq1g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Sep 26, 2022</p>
          </div>
        </div>
        <div className="row row-push-down">
          <div className="col-md-6">
            <div className="video-container">
              <iframe width="100%" src="https://www.youtube.com/embed/ItrAfvtDiO0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <p>Sep 21, 2022</p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(Audio);