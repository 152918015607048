import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
 
var tools = require('../tools/_list.js').default;

class ToolRoute extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="body-stretch">
      <Header/>


      <div className="container sketch-list">
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-300">tools</h2>
            <p>
              I grew tired of many of the ad-bloated and likely backend-logging mini web tools that exist for simple tasks, so sometimes I make my own.
            </p>
            <p>
              You're free to use them, but these are mostly here for my own quick trusted reference.
            </p>
          </div>
        </div>
        <hr/>
        
        <div className="row">
          
          {
            tools.map((t, i)=>{
              return <div className="col-md-12 sketch-thumbnail" key={i}>

                <div className="sketch-thumbnail-number">{tools.length - i}.</div> 
                <div className="sketch-thumbnail-content">
                  <div><Link to={"/tools/" + t.id} className="sketch-thumbnail-link back-filled">{t.id}</Link></div> 
                  <div className="sketch-thumbnail-description">{t.description}</div>
                </div>
              </div>
            })
          }
        </div>
      </div>

      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(ToolRoute);