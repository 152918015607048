import * as THREE from 'three';

const LoopingThree = (id, initialize, drawLoop) => {
  var camera;
  var scene;
  var renderer;
  var frameCounter = 0;
  var dimensions;
  var w; 
  var h; 

  const resize = () => {
    var el = document.getElementById(id);
    dimensions = el.getBoundingClientRect();
    w = dimensions.right - dimensions.left;
    h = dimensions.bottom - dimensions.top;
    renderer.setSize( window.innerWidth, window.innerHeight );
    if(camera){
      camera.aspect = w / h;
      camera.updateProjectionMatrix();
    }
  }
  
  renderer = new THREE.WebGLRenderer( { antialias: true } );

  resize();
  
  // set camera
  camera = new THREE.PerspectiveCamera( 70, w / h, 0.01, 10 );
  camera.position.z = 1;
  scene = new THREE.Scene();

  var el = document.getElementById(id)

  renderer.domElement.setAttribute('id', id);
  renderer.domElement.setAttribute('class', 'sketch');;
  el.replaceWith(renderer.domElement);

  if(drawLoop === undefined){
    drawLoop = initialize;
    console.log(id + ' has no custom initialization.');
  } else {
    console.log(id + ' has been initialized.');
    initialize(scene, {
      w: w, 
      h: h, 
      camera: camera,
      THREE: THREE
    });
  }
   
  const loop = () => {

    drawLoop(scene, {
      w: w,
      h: h,
      camera: camera,
      THREE: THREE,
      frameCounter: frameCounter,
    });

    frameCounter++;
   
    if(document.getElementById(id)){
      requestAnimationFrame(loop);
      renderer.render( scene, camera );
    } else {
      console.log(id + ' has been removed.');
      window.removeEventListener('resize', handleResize);
      // window.removeEventListener('mousemove', handleMouseMove);
    }

    // requestAnimationFrame( loop );
  }

  const handleResize = (e) => {
    resize();
  }

  window.addEventListener('resize', handleResize);

  loop();

  return this;
}



export default LoopingThree;