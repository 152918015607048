import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var loc = [];
    var spring_loc = [];
    var spring = [];
    var frames_between = 60;
    var times_rotated = 0;
    var last_change = -frames_between;
    var radius = 200;
    var theta = 30;

    LoopingCanvas('dot-rotate', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
      },
      (ctx, e) => {
        // loop
        ctx.resetTransform();

        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillRect(0, 0, e.w, e.h);

       
        ctx.translate(e.w/2, e.h/2);
        
        if(e.frameCounter - last_change > frames_between){
          loc = [];
          spring = [];

          radius = 20 + Math.random() * 300;

          times_rotated++;
          
          last_change = e.frameCounter;

          for(var i = times_rotated * theta; i < 360 + times_rotated * theta; i+= theta){
            spring.push(Math.random() * .1);
            
            var temp_r = (20 - 200 * spring[spring.length - 1]) * 2;
            if(temp_r < 5) temp_r = 5;

            loc.push({
              x: radius * Math.cos(i * Math.PI/180),
              y: radius * Math.sin(i * Math.PI/180),
              r: temp_r
            })
          }
          
        }

        if(loc.length === 0) return;

        if(spring_loc.length == 0) spring_loc = loc;

        for(var i in loc){
          spring_loc[i].x = spring[i] * loc[i].x + (1 - spring[i]) * spring_loc[i].x;
          spring_loc[i].y = spring[i] * loc[i].y + (1 - spring[i]) * spring_loc[i].y;
          spring_loc[i].r = spring[i] * loc[i].r + (1 - spring[i]) * spring_loc[i].r;

          // console.log(spring_loc[i].r);

          ctx.beginPath();
          ctx.arc(spring_loc[i].x, spring_loc[i].y, spring_loc[i].r, 0, 2 * Math.PI);
          ctx.fillStyle = '#000';
          ctx.fill();
        }



        function drawCurvedLoop(points){
          var points = JSON.parse(JSON.stringify(points));
          var next_loc;
          ctx.beginPath();
          ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
          for(i = 1; i < points.length; i++){
            next_loc = points[i + 1];
            if(!next_loc) next_loc = points[0];

            var xc = (points[i].x + next_loc.x) / 2;
            var yc = (points[i].y + next_loc.y) / 2;
            ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
          }

          var xc = (points[0].x + points[1].x) / 2;
          var yc = (points[0].y + points[1].y) / 2;
          ctx.quadraticCurveTo(points[0].x, points[0].y, xc, yc);
          ctx.stroke();
          // ctx.fill();
        }
            
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="dot-rotate" key="dot-rotate"/>
      </div>
    )
  }
}

export default Sketch;
