import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
import ToolManager from '../components/ToolManager/ToolManager.js'


class ToolFullScreen extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    var urlParams = new URLSearchParams(window.location.search);
    var clean = urlParams.get('clean');

    return <div className="body-stretch">
      { !clean && <Header/> }
      <ToolManager tool={this.props.match.params.tool_id}/>
      { !clean && <Footer/> }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(ToolFullScreen);