import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var loc = [];
    var spring_loc = [];
    var spring = .02;
    var frames_between = 60;
    var last_change = -frames_between;
    var grid_size = 100;
    var dot_number = 50;

    LoopingCanvas('dot-rotate', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
        for(var i = 0; i < dot_number; i++){
          loc.push({
            x: e.w/2,
            y: e.h/2,
            r: 10
          })
          spring_loc.push({
            x: e.w/2,
            y: e.h/2,
            r: 10
          })
        }        
      },
      (ctx, e) => {
        // loop
        ctx.resetTransform();

        // ctx.fillStyle = 'rgba(255,255,255,0)';
        // ctx.fillRect(0, 0, e.w, e.h);

        var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.putImageData(imageData, 5, -5);

        if(e.frameCounter - last_change > frames_between){
          for(var i in loc){
            // if(Math.random() > .995){
              loc[i].r = Math.random() * 10;
              // change
              if(Math.random() > .5){
                // x
                if(Math.random() > .5 && loc[i].x - grid_size > 0){
                  // left
                  loc[i].x -= grid_size;
                } else if(loc[i].x + grid_size < e.w){
                  // right
                  loc[i].x += grid_size;
                }
              } else {
                // y
                if(Math.random() > .5 && loc[i].y - grid_size > 0){
                  // up
                  loc[i].y -= grid_size;
                } else if(loc[i].y + grid_size < e.h){
                  // down
                  loc[i].y += grid_size;
                }
              }
            // }
          }

          last_change = e.frameCounter;
        }

        function drawCurvedLoop(points){
          var points = JSON.parse(JSON.stringify(points));
          var next_loc;
          ctx.beginPath();
          ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
          for(i = 1; i < points.length; i++){
            next_loc = points[i + 1];
            if(!next_loc) next_loc = points[0];

            var xc = (points[i].x + next_loc.x) / 2;
            var yc = (points[i].y + next_loc.y) / 2;
            ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
          }

          var xc = (points[0].x + points[1].x) / 2;
          var yc = (points[0].y + points[1].y) / 2;
          ctx.quadraticCurveTo(points[0].x, points[0].y, xc, yc);
          ctx.stroke();
          ctx.fill();
        }
            

        if(loc.length === 0) return;

        if(spring_loc.length == 0) spring_loc = loc;

        for(var i in loc){
          spring_loc[i].x = spring * loc[i].x + (1 - spring) * spring_loc[i].x;
          spring_loc[i].y = spring * loc[i].y + (1 - spring) * spring_loc[i].y;
          spring_loc[i].r = spring * loc[i].r + (1 - spring) * spring_loc[i].r;

          ctx.beginPath();
          ctx.lineWidth = 1;
          ctx.arc(spring_loc[i].x, spring_loc[i].y, spring_loc[i].r, 0, 2 * Math.PI);
          ctx.fillStyle = '#fff';
          ctx.strokeStyle ='#000';
          ctx.fill();
          ctx.stroke();

          // var m = spring_loc[i];

          // var nearby = [{
          //   x: m.x,
          //   y: m.y,d
          // }];
          // for(var j in spring_loc){
          //   if(i != j){
          //     var n = spring_loc[j];
          //     if(n.x != m.x && n.y != m.y){
          //       var d = Math.sqrt(Math.pow(n.x - m.x, 2) + Math.pow(n.y - m.y, 2));
                
          //       if(d < grid_size * 2){
          //         nearby.push({
          //           x: n.x,
          //           y: n.y,
          //           d: d
          //         })
          //       }
          //     }
          //   }
          // }

          // nearby.sort(function(a, b){
          //   if(a.d < b.d) return -1;
          //   if(a.d > b.d) return 1;
          // })

          // nearby = nearby.slice(0, 3);

          // var total_distance = 0;
          // for(var i in nearby){
          //   total_distance += nearby[i].d;
          // }

          // if(nearby.length > 1){
          //   // console.log(total_distance);
          //   ctx.lineWidth = 3;
          //   // ctx.strokeStyle = '#000';
          //   // ctx.fillStyle = '#fff';
          //   var o = 1 - total_distance / (grid_size * 6);
          //   if(o < 0) o = 0;
          //   if(o > 1) o = 1;
          //   ctx.strokeStyle = 'rgba(0,0,0,' + o + ')';

          //   ctx.fillStyle = 'rgba(255,255,255,' + o + ')';
          //   drawCurvedLoop(nearby);

          // }

        }



      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="dot-rotate" key="dot-rotate"/>
      </div>
    )
  }
}

export default Sketch;
