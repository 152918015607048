import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { showTooltip, hideTooltip } from '../../actions/actions.export.js'
import './CustomField.css';

var fieldTimeout;

class CustomField extends Component {
  constructor(props){
    super(props);

    this.state = {
      name: props.name,
      value: props.value || '',
      serverErrors: props.serverErrors,
      changeCounter: 0,
      blurCounter: 0,
      showPassword: false
    }

    this.handleTouch = this.handleTouch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.togglePasswordShow = this.togglePasswordShow.bind(this);
    this.checkClientValidation = this.checkClientValidation.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.getErrors = this.getErrors.bind(this);
  }

  handleMouseEnter(e){
    const { dispatch } = this.props;

    if(this.props.disabled) return;

    var err = this.getErrors();
    if((err && err !== this.props.description) || this.state.clientError){
      dispatch(showTooltip({
        e: e,
        nobr: false,
        position: 'bottom',
        content: <div style={{maxWidth: 150}} className="text-danger">{err || this.state.clientError}</div>
      }))
    }
  }

  handleMouseLeave(){

    const { dispatch } = this.props;

    dispatch(hideTooltip());
  }

  handleTouch(){
    if(this.props.onTouch){
      this.props.onTouch();
    }
    this.setState({
      touched: true,
      focus: true
    });
  }

  handleBlur(e){
    if(this.props.onBlur){
      this.props.onBlur();
    }
    this.setState({
      focus: false,
      blurCounter: this.state.blurCounter + 1
    });

    if(this.props.onBlurEvent){
      this.props.onBlurEvent(e.target.value);
    }
  }

  checkClientValidation(value){
    const { dispatch } = this.props;

    if(this.props.clientValidation){
      var cv = this.props.clientValidation(value);

      // if(!cv) dispatch(hideTooltip());

      this.setState({
        clientError: cv
      });
    }
  }

  handleChange(e){

    const value = e.target.value;
    const valid = e.target.validity.valid;

    this.checkClientValidation(value);

    var el = document.getElementById('textarea_' + this.props.name);
    if(this.props.multipleRows && el !== undefined){
      el.style.cssText = 'height:auto;';
      let height = (2 + el.scrollHeight) + 'px';
      el.style.cssText = 'height:' + height + ';';
    }

    if(this.props.onChangeEvent){

      if(this.props.timeout){
        clearTimeout(fieldTimeout);
        fieldTimeout = setTimeout((t)=>{
          if(valid){
            this.props.onChangeEvent(value);
          } else {
            this.props.onChangeEvent('');
          }
        }, this.props.timeout);
      } else {
        if(valid){
          this.props.onChangeEvent(value);
        } else {
          this.props.onChangeEvent('');
        }
      }
    } 

    this.setState({
      value: value,
      internalChange: true,
      changeCounter: this.state.changeCounter + 1
    });
  }

  componentDidMount(){
    const props = this.props;
    this.setState({
      valueOnMount: this.state.value
    });

    var el = document.getElementById('textarea_' + this.props.name);

    if(this.props.multipleRows && el !== undefined){
      setTimeout(function(){
        el.style.cssText = 'height:auto;';
        var height = (2 + el.scrollHeight) + 'px';
        el.style.cssText = 'height:' + height + ';';
      })
    }
  }

  togglePasswordShow(){

    var ps = this.state.showPassword;
    ps = !ps;
    console.log('togglePasswordShow', ps);
    this.setState({
      showPassword: ps
    });
  }

  getErrors(){ 
    const { dispatch } = this.props;

    const props = this.props;

    if(this.state.serverErrors && !this.state.internalChange) return this.state.serverErrors;
    
    if(!this.state.touched && this.state.value === '' && this.state.changeCounter === 0) return;

    let v = this.state.value || "";
    
    if(props.maxLength !== undefined){
      if(v.length > props.maxLength){
        return "Too long: " + v.length + '/' + props.maxLength;
      }
    }

    if(this.state.blurCounter > 0){
      // check length
      if(props.minLength !== undefined){
        if(v.length < props.minLength){
          return "Must be at least " + props.minLength + " characters.";
        }
      }
      

      if(v === "" && props.required){
        return props.description || "Required.";
      }
    }


  }

  render(){
    let that = this;
    const props = this.props;
    let pristine = true;

    let err = this.getErrors();

    return  <div className={"field " + (this.props.inline ? " no-margin" : "")}>
      <div>
        {
          this.props.inline ? "" : (
            <span className={"field-label " + (this.state.value || this.props.label ? "" : "field-label-hidden") + (this.props.disabled ? " field-label-disabled" : "") }>
              {(this.props.label || this.props.placeholder || this.props.name) + (this.props.required ? '*' : '')}
            </span>
          )
        }
      </div>
      <div className="position-relative" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
      {
        !this.props.multipleRows ? (
          <input 
            className={"input " + (err || this.state.clientError ? "input-error" : "") + (" input-" + this.props.size) + (this.props.inline ? " input-inline" : "")} 
            type={this.props.type === 'password' && this.state.showPassword ? 'text' : this.props.type || 'text'} 
            name={this.props.name}
            value={this.state.value}
            onClick={this.handleTouch}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={(this.props.placeholder || this.props.name) + (this.props.required && !this.props.label ? ' *' : '')}
            disabled={this.props.disabled} 
            required={this.props.required}
            minLength={this.props.minLength}
            />
        ) : (
          <textarea
            id={'textarea_' + this.props.name}
            className={"input " + (err || this.state.clientError ? "input-error" : "") + (" input-" + this.props.size)} 
            type={this.props.type || 'text'} 
            name={this.props.name}
            rows={this.props.minRows || 2}
            value={this.state.value}
            onClick={this.handleTouch}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={(this.props.placeholder || this.props.name) + (this.props.required && !this.props.label ? ' *' : '')}
            disabled={this.props.disabled} 
            required={this.props.required}
            minLength={this.props.minLength}
          ></textarea>
        )
      }
      {
        this.props.type === 'password' && <span className="field-icon" key={this.state.showPassword} onClick={this.togglePasswordShow}>
          <i className={"far fa-lg fa-fw clickable " + (this.state.showPassword ? "fa-eye-slash" : "fa-eye")}/>
        </span>
      }
      {
        ((err || this.state.clientError) && this.props.inline) && <span className="field-icon-inline" key={this.state.showPassword} onClick={this.togglePasswordShow}>
          <i className="fas fa-lg fa-fw fa-exclamation-circle text-danger"/>
        </span>
      }
      </div>
      {
        this.props.inline ? "" : (
          <div>
            {
              err ? (
                <div className="field-error">
                  <span>{ err }</span>
                </div>
                ) : (
                <div className={"field-description" + (this.props.disabled ? " field-description-disabled" : "") }>
                  <span>{ this.props.description }</span>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  }

  componentWillReceiveProps(newprops){
    if(newprops.serverErrors !== this.state.serverErrors){
      this.setState({
        lastServerErrors: this.state.serverErrors,
        serverErrors: newprops.serverErrors,
        internalChange: false
      })
    }
    
    if(newprops.autoUpdate){
      if(newprops.value !== this.state.value){
        this.setState({
          value: newprops.value
        });
      }
    }
    setTimeout((e)=>{
      this.checkClientValidation(this.state.value);  
    })
  }
};


CustomField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  onTouch: PropTypes.func,
  onChangeEvent: PropTypes.func,
  onBlurEvent: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  error: PropTypes.string
}


const mapStateToProps = (state) => {
  const { 
    
    //...
  } = state;

  return {
    
    //...
  }
}

export default connect(mapStateToProps)(CustomField);
