import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
import SketchManager from '../components/SketchManager/SketchManager.js'


var sketches = require('../sketches/_list.js').default;
sketches.reverse();

class SketchFullScreen extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    var urlParams = new URLSearchParams(window.location.search);
    var clean = urlParams.get('clean');

    return <div className="body-stretch">
      {
        !clean && <Header/>
      }
      <div className="screen-height-container">
        <div className="shc-content">
           <SketchManager sketch={this.props.match.params.sketch_id}/>
        </div>

        {
          !clean && <div className="shc-bottom-content">
            <Footer/>
          </div>
        }
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(SketchFullScreen);