import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var size = 30;

    var x = 0;
    var y = 0;

    var lastCounter = 1;
    var framesBetween = 1;


    var directions = [];
    var resolution = 4;
    for(var i = 0; i < Math.PI * 2; i += Math.PI * 2 / resolution){
      directions.push(i);
    }

    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.fillStyle = '#E8D6CB';
        ctx.fillRect(0, 0, e.w, e.h);
        
        x = e.w/2;
        y = e.h/2;
      },
      (ctx, e) => {
        // loop
        ctx.fillStyle = 'rgba(232,214,203,.005)';
        ctx.fillRect(0, 0, e.w, e.h);

        if(e.frameCounter - lastCounter > framesBetween){
          lastCounter = e.frameCounter;

        } else {
          return;
        }

        var angle = directions[parseInt(Math.random() * directions.length)];

        x += size * Math.cos(angle);
        y += size * Math.sin(angle);

        if(x < 0) x -= size * Math.cos(angle);
        if(x > e.w) x -= size * Math.cos(angle);

        if(y < 0) y -= size * Math.sin(angle);
        if(y > e.h) y -= size * Math.sin(angle);

        // console.log('go', e.frameCounter, x, y);

        ctx.fillStyle = '#5D2E46';
        ctx.strokeStyle = '#5D2E46';
        ctx.fillRect(x - size / 2, y - size / 2, size, size);
        ctx.strokeRect(x - size / 2, y - size / 2, size, size);
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
