import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var system = {
      level: 0,
      rotate: Math.random() * Math.PI * 2,
      color: '#292F36',
      moons: [
        {
          level: 1,
          rotate: Math.random() * Math.PI * 2,
          color: '#4ECDC4',
          moons: [
            {
              level: 2,
              rotate: Math.random() * Math.PI * 2,
              color: '#FF6B6B',
              moons: [
                {
                  level: 3,
                  rotate: Math.random() * Math.PI * 2,
                  color: '#008DD5',
                  moons: [
                    {
                      level: 4,
                      rotate: Math.random() * Math.PI * 2,
                      color: '#DFBBB1',
                      moons: [
                        {
                          level: 5,
                          rotate: Math.random() * Math.PI * 2,
                          color: '#F77F00',
                          moons: [
                            {
                              level: 6,
                              rotate: Math.random() * Math.PI * 2,
                              color: '#F77F00',
                              moons: [
                                {
                                  level: 7,
                                  rotate: Math.random() * Math.PI * 2,
                                  color: '#F77F00',
                                  moons: [
                                    {
                                      level: 8,
                                      rotate: Math.random() * Math.PI * 2,
                                      color: '#F77F00',
                                      moons: [
                                        {
                                          level: 9,
                                          rotate: Math.random() * Math.PI * 2,
                                          color: '#F77F00',
                                          moons: [
                                            {
                                              level: 10,
                                              rotate: Math.random() * Math.PI * 2,
                                              color: '#F77F00',
                                              moons: [
                                              
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }

    var radius;

    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
      },
      (ctx, e) => {
        // loop
        for(var i = 0; i < 10; i++){
          ctx.resetTransform();

          // ctx.clearRect(0, 0, e.w, e.h);
          
          ctx.translate(e.w/2, e.h/2);
          function draw(s){
            var radius = 0;
            if(s.level > 0){
              radius = 150 / s.level;
            }

            ctx.translate(radius * Math.cos(s.rotate), radius * Math.sin(s.rotate));
            ctx.rotate(s.rotate);
            ctx.strokeStyle = "#000"; 
            ctx.fillStyle = '#fff';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.arc(0, 0, 50 / (s.level + 1), 50 / (s.level + 1), 0, Math.PI * 2);
            ctx.stroke();
            ctx.fill();

            s.rotate += .001 * s.level;
            for(var i in s.moons){
              draw(s.moons[i]);
            }
          }

          draw(system);
        }
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
