const work = [
  // {
  //   id: 'free-gum-analysis',
  //   title: "Free Gum and Office Socialization",
  //   description: 'Could a bowl of free gum become the new office water cooler and a valuable line of business for Wrigley?',
  //   thumbnail: '',
  //   date: '2019-06-07'
  // },
  {
    id: 'bottle-cam',
    title: "Spray Bottle Product Research",
    description: "What happens after a customer buys a cleaning product? What surfaces are actually cleaned with it in their home? Where is it stored when its not in use? These questions and many more were the prompt for an R&D project for a large CPG-cleaning company. We solved them by custom designing a camera and accelerometer attached to the top of products that lived in study participant's homes for a month. The output was a new model for understanding the role these tools play in different cleaning routines and a new product design for cleaner that is meant to live on your counter top instead of your cupboard.",
    asset: 'bottle-cam.mp4',
    assetType: 'video',
    date: '2014-06',
    company: 'Iota Partners'
  },
  {
    id: 'bluetooth-grocery-store',
    title: "Bluetooth Grocery Store",
    description: "To help investigate why Skittles sales were down in grocery stores compared to convenience stores, we studied how people made planned vs impulse purchase decisions throughout a grocery store's layout. To do this, we outfitted a store with more than 50 handmade Bluetooth beacons (pre-BLE) and strapped Android phones running a custom sniffing app to paid volunteer's shopping carts.",
    asset: 'bluetooth-grocery-store.mp4',
    assetType: 'video',
    date: '2012-08',
    company: 'Iota Partners'
  },
  {
    id: 'snake-ai',
    title: "Interactive Snake AI",
    description: 'While working on an AI consulting project for a large office furniture manufacturer, I explored genetic based AI generation using the game of snake as an example. You can play along and customize the settings and success metrics in an interactive version of my code using the link below.',
    asset: 'snake-ai.png',
    links: [
      {
        url: "https://pbinggeser.github.io/snake-ai/",
        display: "Train AI in your browser"
      },
      {
        url: "https://medium.com/becoming-human/designing-ai-solving-snake-with-evolution-f3dd6a9da867",
        display: "Read more about how this works"
      },
    ],
    company: 'Personal',
    date: '2017-09'
  },
  // {
  //   id: 'prospector',
  //   title: "DMS Prospector Notebook",
  //   description: 'short description',
  //   thumbnail: '',
  //   date: '2019-06-07'
  // },
  // {
  //   id: 'io-wrapper',
  //   title: "DMS I/O Wrapper",
  //   description: 'short description',
  //   thumbnail: '',
  //   date: '2019-06-07'
  // },
  {
    id: 'dms-platform',
    title: "Platform for Packaged Analytics",
    description: 'Data Mining & Supply was a start up I co-founded in an effort to make the process of making sense of data easier and more adaptable do different circumstances. Our core product was a package manager for analytic functions, where each package leveraged the same overall structure, could be nested within each other, and could be executed within your codebase or called via an easy standardized API.',
    company: 'Data Mining & Supply LLC',
    asset: 'dms-platform.mp4',
    assetType: 'video',
    date: '2017-04'
  },
  {
    id: 'mountain-dew-super-bowl',
    title: "Mountain Dew Super Bowl Contest",
    company: 'Twitter',
    description: "Mountain Dew came to my team at Twitter with a prompt: with $1,000,000 to give away and John Cena in our ad, how can we become the most talked about brand during the Super Bowl? We designed an end-to-end campaign about guessing the number of bottles in their commercial, leveraging John Cena's Twitter account, an interactive bot that replied as people Tweeted based on their guess, and encouraged them to guess multiple times to win the prize. By the end of the game, more than 200,000 valid guesses were Tweeted, the brand had the #2 and #3 trending terms in the US, and our tech helped encourage so much conversation that more people Tweeted about Mountain Dew as their ad aired than about the first touchdown of the game.",
    asset: 'mountain-dew-super-bowl.png',
    links: [
      {
        url: "https://www.adweek.com/brand-marketing/super-bowl-advertiser-mountain-dew-major-melon-contest/",
        display: "Read the article in Adweek"
      }
    ],
    date: '2021-02'
  },
  {
    id: 'mymcdonalds-launch',
    title: "Rewarding love for McDonalds",
    company: 'Twitter',
    description: "In 2021, I developed a framework for incorporating and rewarding their existing fandom into each of their campaigns throughout the year. For McDonald's biggest on-Twitter launch ever, I designed the technology and experience for a campaign that would convert past brand love (likes of Tweets by McDonalds) into rewards points within their new loyalty program. All fans needed to do was tap the heart on the launch Tweet, and they'd instantly get a link that they could use that literally rewarded them for their previous brand love.",
    asset: 'mymcdonalds-launch.png',
    links: [
      {
        url: "https://adage.com/article/marketing-news-strategy/mcdonalds-behavior-based-campaign-aims-boost-trial-its-rewards-program/2352666",
        display: "Read the article in Adweek"
      }
    ],
    date: '2021-07'
  },
  {
    id: 'personalization-engine',
    title: "Tweet Personalization Engine",
    description: "Oreo's parent company Mondelez came to us with a challenge: incorporate personalization and empathy into each major brand campaign throughout the year. I designed a method and technical process that leveraged public Twitter data to enable Mondelez to pair users with brand content, based entirely on how each individual used Twitter. This rules engine combined data science prediction, ad-targeting, and a clever use of completely public signals to deliver more than 200x the earned engagement that the brand was used to seeing.",
    company: 'Twitter',
    asset: 'personalization-engine.png',
    links: [
      {
        url: "https://marketing.twitter.com/en/success-stories/best-of-tweets-2021",
        display: "Checkout Twitter's Best of Tweets 2021"
      }
    ],
    date: '2020-04'
  },
  {
    id: 'pattycake',
    company: "Not Yet Digital",
    title: "Pattycake",
    description: "What started as a simple tool-request from a good friend and licensed architect has turned into a profitable architecture software company, with Pattycake as its first product. Pattycake is a SaaS web-based tool for creating, editing, and managing .PAT hatch files. Thousands of architects, designers, and construction companies now use Pattycake around the globe on nearly every continent. At Not Yet Digital, I'm the sole engineer, UX designer, and product manager - still working in collaboration with my now business partner to build architecture software for the next generation.",
    links: [
      {
        url: "https://pattycake.io",
        display: "Try Pattycake for free, right in your browser"
      }
    ],
    asset: 'pattycake.png',
    date: '2019-10'
  }
]

export default work; 