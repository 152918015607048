import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'

class Contact extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="body-stretch">
      <Header/>
      <div className="container" style={{marginTop: 50}}>
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="headshot-wrapper">
              <img src="/img/headshot2.png" className="headshot list-item"/>
            </div>
            <p className="lead" style={{marginBottom: 5}}>👋 &nbsp;say hey:</p>
            <ul className="list-unstyled text-left">
              <li>
                <a className="back-filled" href="mailto:peter@binggeser.com"><i className="far fa-envelope fa-lg fa-fw"/> peter@binggeser.com</a>
              </li>
              <li>
                <a className="back-filled" href="https://twitter.com/peterbinggeser"><i className="fab fa-twitter fa-lg fa-fw"/> @peterbinggeser</a>
              </li>
              {/*<li>
                <a className="back-filled" href="https://dribbble.com/peterbinggeser"><i className="fab fa-dribbble fa-lg fa-fw"/> @peterbinggeser</a>
              </li>*/}
              <li>
                <a className="back-filled" href="https://medium.com/@peterbinggeser"><i className="fab fa-medium-m fa-lg fa-fw"/> @peterbinggeser</a>
              </li>
              <li>
                <a className="back-filled" href="https://linkedin.com/in/peterbinggeser"><i className="fab fa-linkedin fa-lg fa-fw"/> @peterbinggeser</a>
              </li>
            </ul>
          </div>
          <div className="col-md-8">
            <h1 style={{marginBottom: 0}}>
              Peter Binggeser
            </h1>
            <p className="lead text-muted"><em>(pete bing-gazer)</em></p>
            <hr/>
              
            <small><i>currently:</i></small>
            <p> 
              Co-Founder @ <a className="back-filled" target="_blank" href="https://zerowidth.ai">ZeroWidth</a><br/>
              Founder @ <a className="back-filled" target="_blank" href="https://not.yet.digital">Not Yet Digital</a><br/>
              <i className="fal fa-angle-right fa-fw"/> Head of Design & Development on <a className="back-filled" target="_blank" href="https://pattycake.io">Pattycake</a><br/>
            </p>
            <small><i>formerly:</i></small>
            <p>
              Rapid Prototyping & AI Consultant @ <a className="back-filled" target="_blank" href="https://corridorgroup.com/">Corridor</a><br/>
              Creative Technology Lead - Twitter Next @ <a className="back-filled" target="_blank" href="https://twitter.com">Twitter</a><br/>
              Associate Board Member @ <a className="back-filled" target="_blank" href="https://weallcode.org">We All Code</a><br/>
              Co-Founder @ <strong>Data Mining & Supply</strong><br/>
              Adjunct Faculty @ <a className="back-filled" target="_blank" href="https://id.iit.edu">IIT Institute of Design</a><br/>
              <i className="fal fa-angle-right fa-fw"/> Theories of UCD: The Future of Experience Research and the Internet of Everything<br/>
              <i className="fal fa-angle-right fa-fw"/> Sensors & Sensibilities: an IOT studio<br/>
              <i className="fal fa-angle-right fa-fw"/> Data as a Resource for Design<br/>
              Manager - Experience Technology Studio @ <a className="back-filled" target="_blank" href="https://publicissapient.com">Sapient Nitro <small>(acquired)</small></a><br/>
              Creative Technologist @ <a className="back-filled" target="_blank" href="https://publicissapient.com">iota partners <small>(acquired)</small></a><br/>
            </p>
          </div>
          <div className="col-md-4">
            
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(Contact);