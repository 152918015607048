import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    var numLines = 20;

    var lines = [];
    var padding = 100;


    LoopingCanvas('sketchy-lines', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
        for(var i = 0; i < numLines; i++){
          lines.push({
            x1: i * (e.w - padding * 2) / (numLines - 1) + padding,
            y1: padding,
            x2: i * (e.w - padding * 2) / (numLines - 1) + padding,
            y2: e.h - padding,
            index: i
          })
        }
      },
      (ctx, e) => {
        // loop

        function drawCurvedLine(points){
          var points = JSON.parse(JSON.stringify(points));
          var next_loc;
          ctx.beginPath();
          ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
          for(var i = 1; i < points.length; i++){
            next_loc = points[i + 1];
            if(!next_loc) continue;

            var xc = (points[i].x + next_loc.x) / 2;
            var yc = (points[i].y + next_loc.y) / 2;
            ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
          }
        }

        function sketchyLine(x1, y1, x2, y2, sketchyness){

          function sketch(){
            return sketchyness * (Math.random() - .5) /2;
          }

          for(var i = 0; i < sketchyness / 20; i++){
            ctx.beginPath();
            ctx.moveTo(x1 + sketch(), y1 + sketch());
            ctx.lineTo(x2 + sketch(), y2 + sketch());
            ctx.lineWidth = (numLines - sketchyness/2)/5 + 1;
            ctx.stroke();
          }
        }


        
        ctx.resetTransform();
        ctx.clearRect(0, 0, e.w, e.h);

        ctx.strokeStyle = "#000";
        ctx.lineCap = 'round';
        for(var i in lines){
          var l = lines[i];
          sketchyLine(l.x1, l.y1, l.x2, l.y2, numLines + Math.sin(e.frameCounter / 50 + i/numLines/2 * Math.PI * 2) * numLines);
        }
        
      }
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketchy-lines" key="sketchy-lines"/>
      </div>
    )
  }
}

export default Sketch;
