import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
import WorkManager from '../components/WorkManager/WorkManager.js'
 
var work = require('../work/_list.js').default;
// work.reverse();

class WorkList extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="body-stretch">
      <Header/>

      <div className="container work-list">
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-300">work</h2>
            <p>
              A brief sample of some of my favorite projects throughout my career, across various roles and companies.
            </p>
          </div>
        </div>
        <hr/>
        
        
          
          {
            work.map((w, i)=>{
              return <div className="row work-thumbnail" key={i}>
                <div className="col-md-6">
                  {/*<Link to={"/work/" + w.id}>*/}
                  <div>
                    <div  className="work-thumbnail-title">
                      <span className="work-thumbnail-number">{work.length - i}.</span> <span className="work-thumbnail-link">{w.title}</span>
                    </div>
                    {
                      w.assetType === 'video' ?
                      <video width="100%" controls autoplay className="work-thumbnail-image">
                        <source src={"/img/work/" + w.asset} type="video/mp4"/>
                      </video>
                      :
                      <img src={"/img/work/" + w.asset} className="work-thumbnail-image"/>
                    }
                  {/*</Link>*/}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="work-thumbnail-content">
                    <div className="work-thumbnail-date">{w.company} - {w.date}</div>
                    <div className="work-thumbnail-description">{w.description}</div>
                    {
                      w.links && w.links.map((l, j)=>{
                        return <div key={j} className="work-thumbnail-detail-link">
                          <a href={l.url} target="_blank" className="back-filled">{l.display}</a>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            })
          }
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(WorkList);