import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';


var x,y;

var key = {
  left: false,
  right: false,
  up: false,
  down: false
}

var v = {
  x: 0,
  y: 0
}

var sprite = {
  w: 5,
  h: 25
}

var jump_velocity = 3;
var jump_height_counter = 0;
var jump_affected_frames = 8;
var landed_speed = 1.5;
var air_speed = .5;
var gravity = .2;

var landed = false;
var air_friction = .9;
var landed_friction = .8;

class Sketch extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){

    LoopingCanvas('platformer', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);

        x = e.w/2;
        y = e.h/2;
        
      },
      (ctx, e) => {


        y += v.y;
        x += v.x;

        if(y > e.h - sprite.h){
          y = e.h - sprite.h;
          landed = true;

          if(key.left){
            v.x -= landed_speed;
          }
          if(key.right){
            v.x += landed_speed;
          }
        } else {
          landed = false;

          if(key.left){
            v.x -= air_speed;
          }
          if(key.right){
            v.x += air_speed;
          }
        }

        if(jump_height_counter < jump_affected_frames && key.up){
          if(jump_height_counter === 0){
            v.y = -jump_velocity;  
          } else {
            v.y += -jump_velocity / 5;
          }
          jump_height_counter++;
        }

        v.y += gravity;
        if(landed){
          v.x *= landed_friction;
        } else {
          v.x *= air_friction;
        }

        // loop
        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, e.w, e.h);

        ctx.fillStyle = '#fff';
        ctx.beginPath()
        ctx.moveTo(x, y + sprite.h);
        ctx.lineTo(x + v.x * 2, y);
        ctx.lineTo(x + v.x * 2 + sprite.w, y);
        ctx.lineTo(x + sprite.w, y + sprite.h);
        ctx.fill();
      }
     )

    document.getElementById('platformer').addEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').addEventListener("keyup", this.keyListenerUp, false);
    document.getElementById('platformer').focus();
  }

  componentWillUnmount(){
    document.getElementById('platformer').removeEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').removeEventListener("keyup", this.keyListenerUp, false);
  }

  keyListenerDown(e){
    // console.log(e);

    switch(e.key){
      case 'ArrowUp':
        key.up = true;
        if(landed){
          jump_height_counter = 0;
        }
        break;
      case 'ArrowDown':
        key.down = true;
        break;
      case 'ArrowLeft':
        key.left = true;
        break;
      case 'ArrowRight':
        key.right = true;
        break;
    }
  }

  keyListenerUp(e){
    // console.log(e);

    switch(e.key){
      case 'ArrowUp':
        key.up = false;
        jump_height_counter = jump_affected_frames;
        
        break;
      case 'ArrowDown':
        key.down = false;
        break;
      case 'ArrowLeft':
        key.left = false;
        break;
      case 'ArrowRight':
        key.right = false;
        break;
    }
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="platformer" tabIndex="0" autoFocus/>
      </div>
    )
  }
}

export default Sketch;
