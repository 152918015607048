import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var spring = .005;

    var spring_angle = 0;
    var spring_x = 0;
    var spring_y = 0;

    var target_angle = 0;
    var target_x = 0;
    var target_y = 0;

    var last_angle = 0;
    var last_x = 0;
    var last_y = 0;

    var cr = 0;
    var cg = 0;
    var cb = 0;

    LoopingCanvas('spinning', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
      },
      (ctx, e) => {
        // loop
        
        ctx.resetTransform();

        ctx.fillStyle = 'rgba(255,255,255,.01';
        ctx.fillRect(0, 0, e.w, e.h);
        ctx.translate(e.w/2, e.h/2);

        var centroid_movement = 1500;

        if(Math.random() > .97){
          target_angle = Math.random() * 360 + 180;
          target_x = Math.random() * centroid_movement - centroid_movement / 2;
          target_y = Math.random() * centroid_movement - centroid_movement / 2;
        }

        last_angle = spring_angle;
        last_x = spring_x;
        last_y = spring_y;

        

        spring_angle = spring * target_angle + (1 - spring) * spring_angle;
        spring_x = spring * target_x + (1 - spring) * spring_x;
        spring_y = spring * target_y + (1 - spring) * spring_y;

        var changes = Math.abs(last_x - spring_x) + Math.abs(last_y - spring_y); 

        ctx.rotate(spring_angle * Math.PI / 180);


        ctx.font = "bold 100px Helvetica";
        ctx.textAlign = "center";
        ctx.lineWidth = 1;

        ctx.strokeText("spinning", 0, 50);

      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="spinning" key="spinning"/>
      </div>
    )
  }
}

export default Sketch;
