import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    LoopingCanvas('crunch-connected-clocks', 
      (ctx, e) => {
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillRect(0, 0, e.w, e.h);
      },
      (ctx, e) => {
        ctx.resetTransform();
        // ctx.clearRect(0, 0, e.w, e.h);

        ctx.fillStyle = 'rgba(255,255,255,.02)';
        ctx.fillRect(0, 0, e.w, e.h);

        

        var r = 50;
        var spacing = r * 2;
        var padding_w = e.w % r / 2;
        var padding_h = e.h % r / 2;


        ctx.lineCap = 'round';

        var t = 30;

        var points = [];
        var reverse_factor = 1;
        for(var lx = 0; (lx + 1) * spacing < e.w - padding_w * 2; lx++){
          var zig = [];

          for(var ly = 0; (ly + 1) * spacing < e.h - padding_h * 2; ly++){
            var x = (lx + 1) * spacing + padding_w;
            var y = (ly + 1) * spacing + padding_h;
            var theta = e.frameCounter/t + (x + y) / 50;
            zig.push({
              x: x + r * Math.cos(theta),
              y: y + r * Math.sin(theta)
            })

            // ctx.lineWidth = 1;
            // ctx.beginPath();
            // ctx.arc(x, y, r, 0, 2 * Math.PI);
            // ctx.strokeStyle = 'rgba(0,0,0,.25)'
            // ctx.stroke();
          }

          if(reverse_factor === 1){
            zig.reverse();
          }
          reverse_factor *= -1;

          points = points.concat(JSON.parse(JSON.stringify(zig)));
        }

        // console.log(points);

        if(points.length > 0){
          ctx.strokeStyle = 'rgba(0,0,0,1)'
          ctx.lineWidth = 5;
          ctx.beginPath();
          ctx.moveTo(points[0].x, points[0].y);
          for(var i = 1; i < points.length; i++){
            ctx.lineTo(points[i].x, points[i].y);
          }
          ctx.stroke();

          for(var i in points){
            // ctx.lineWidth = 1;
            // ctx.beginPath();
            // ctx.arc(points[i].x, points[i].y, 2, 0, 2 * Math.PI);
            // ctx.strokeStyle = '#000';
            // ctx.stroke();
          }
        }
      } 
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="crunch-connected-clocks" key="crunch-connected-clocks"/>
      </div>
    )
  }
}

export default Sketch;
