import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import './SideMenu.css';

import { toggleSideMenu } from '../../actions/actions.export.js'

class SideMenu extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.state = {
      open: false
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSideMenuToggle = this.handleSideMenuToggle.bind(this);
  }


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if(window.scrollY > 0){
      this.setState({
        top: false
      });
    } else {
      this.setState({
        top: true
      });
    }
  }

  handleSideMenuToggle(){
    const { globalReducer, dispatch } = this.props;
    dispatch(toggleSideMenu())
  }

  render(){
    const { globalReducer, dispatch, medium } = this.props;

    return <div id="sidemenu" className={
        (globalReducer.sidemenu.open ? "sidemenu-open " : "sidemenu-closed ") 
         + (this.state.top ? "sidemenu-top" : "sidemenu-scrolling")}
         >
      <div className="sidemenu-list">
        {/*<Link onClick={this.handleSideMenuToggle} to="/" className="sidemenu-link sidemenu-item">home</Link>*/}
        <Link onClick={this.handleSideMenuToggle} to="/about" className="sidemenu-link sidemenu-item">about</Link>
        {/*<Link onClick={this.handleSideMenuToggle} to="/work" className="sidemenu-link sidemenu-item">work</Link>*/}
        {/*{ medium.articles.length > 0 && <Link onClick={this.handleSideMenuToggle} to="/writing" className="sidemenu-link sidemenu-item">writing</Link> }*/}
        {/*<Link onClick={this.handleSideMenuToggle} to="/api" className="sidemenu-link sidemenu-item">api</Link>*/}
        {/*<Link onClick={this.handleSideMenuToggle} to="/sketchbook" className="sidemenu-link sidemenu-item">sketchbook</Link>*/}
        {/*<Link onClick={this.handleSideMenuToggle} to="/tools" className="sidemenu-link sidemenu-item">tools</Link>*/}
        {/*<Link onClick={this.handleSideMenuToggle} to="/audio" className="sidemenu-link sidemenu-item">audio</Link>*/}
        {/*<Link onClick={this.handleSideMenuToggle} to="/brands" className="sidemenu-link sidemenu-item">brands</Link>*/}
        
        
      </div>
    </div>
  }

  compnentWillReceiveProps(newProps){
    this.setState({
      open: newProps.open
    });
  }
}


const mapStateToProps = (state) => {
  const { 
    globalReducer,
    medium
    //...
  } = state;

  return {
    globalReducer,
    medium
    //...
  }
}

export default connect(mapStateToProps)(SideMenu);