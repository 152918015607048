import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
import SketchManager from '../components/SketchManager/SketchManager.js'
 
var brands = require('../brandList.json');
brands = brands.sort((a,b)=>{
  if(a > b) return 1;
  if(a < b) return -1;
})

class BrandList extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;



    return <div className="body-stretch">
      <Header/>

      <div className="container sketch-list">
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-300">brands</h2>
            <p>
              I've had the opportunity to work with a large variety of household brands in various roles over my career. 
            </p>
            <p>
              Some of these were custom ad campaigns, some data science consulting projects, some R&D and prototyping. With each varying broadly in scale and complexity, they're listed here in alphabetical order for my own archive's sake:
            </p>
          </div>
        </div>
        <hr/>
        
        <div className="row">
          
          {
            brands.map((s, i)=>{
              return <div className="col-4 col-md-2 col-sm-3 brand-logo-wrapper " key={i}>
                <img src={"/img/brands/" + s + ".png"} width="100%"/>
              </div>
            })
          }
        </div>
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(BrandList);