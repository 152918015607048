import React, { Component } from 'react'
import Papa from 'papaparse'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
      csv_headers: true
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOutputChange = this.handleOutputChange.bind(this);
   
  }

  handleInputChange(e){
    let csv = ""
    try{
      csv = Papa.unparse(e);
    } catch(err){
      console.log(err);  
    }
   

    this.setState({
      input_string: e,
      output_string: csv
    })
  }

  handleOutputChange(e){
    let json = ""
    try{
      json = Papa.parse(e, {header: this.state.csv_headers});
    } catch(err){
      console.log(err);  
    }
   

    this.setState({
      input_string: JSON.stringify(json.data, null, 2),
      output_string: e
    })
  }

  render(){

    return (
      <div id="json-to-csv">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>json to csv</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter a JSON array here to convert to CSV..."
                minRows={15}
                multipleRows={true}
                autoUpdate={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleInputChange(e); }}
                autoFocus={true}
                lockSize={true}
              />
            </div>
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="...or enter CSV here to convert to JSON."
                minRows={15}
                multipleRows={true}
                autoUpdate={true}
                onChangeEvent={(e) => { this.handleOutputChange(e); }}
                value={this.state.output_string}
                lockSize={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="flex-split">
                <div className="list-item">
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="flex-split">
                <div className="list-item">
                  <Checkbox value={this.state.csv_headers} onToggle={(e)=>{ 
                    this.setState({csv_headers: !this.state.csv_headers}) 
                    setTimeout(()=>{ this.handleOutputChange(this.state.output_string) }, 10);
                  }} switch={true}/> Has headers
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Sketch;
