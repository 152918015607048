import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var birdCount = 200;
    var sight_distance = 50;
    var birds = [];
    var pressure = .1;


    function Bird(x,y){
      this.x = x || 0;
      this.y = y || 0;

      // this.vx = Math.random() - .5;
      // this.vy = Math.random() - .5;

      this.angle = Math.PI * 2 * Math.random();
      this.velocity = 2;

      return this;
    }

    LoopingCanvas('birds', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);

        birds = [];
        for(var i = 0; i < birdCount; i++){
          birds.push(new Bird(Math.random() * e.w, Math.random() * e.h))
        }
        
      },
      (ctx, e) => {
        // loop
        ctx.clearRect(0, 0, e.w, e.h);


        for(var i = 0; i < birds.length; i++){

          var b = birds[i];

          ctx.strokeStyle = 'rgba(0,0,0,.75)';

          ctx.beginPath();
          ctx.moveTo(b.x, b.y);
          ctx.lineTo(b.x + 10 * Math.cos(b.angle), b.y + 10 * Math.sin(b.angle));
          ctx.stroke();

          ctx.beginPath();
          ctx.arc(b.x, b.y, 5, 0, 2 * Math.PI);
          ctx.stroke();
          


          b.angle += (Math.PI * 2 * Math.random() - Math.PI) * .1;

          b.x += b.velocity * Math.cos(b.angle);
          b.y += b.velocity * Math.sin(b.angle);

          if(b.x > e.w) b.x = 0;
          if(b.x < 0) b.x = e.w;
          
          if(b.y > e.h) b.y = 0;
          if(b.y < 0) b.y = e.h;

          var can_see_angle = 0;
          var can_see_count = 0;
          for(var j = 0; j < birds.length; j++){
            if(j == i) continue;
            var o = birds[j];
            var distance = Math.sqrt(Math.pow(o.x - b.x, 2) + Math.pow(o.y - b.y, 2));

            if(distance < sight_distance){
              can_see_count++;
              can_see_angle += o.angle;

              // console.log('hsla(' + parseInt(Math.cos(frameCounter/100) * 15 + 30) + ',73%,20%,' + (distance/sight_distance).toFixed(1) + ')');
              // ctx.lineWidth = 2;
              // ctx.strokeStyle = 'hsla(' + parseFloat(40 * distance/sight_distance) + ',100%,50%,0.1)';
              // ctx.beginPath();
              // ctx.moveTo(b.x, b.y);
              // ctx.lineTo(o.x, o.y);
              // ctx.stroke();
            }
          }

          if(can_see_count > 0){
            can_see_angle /= can_see_count;
            b.angle = can_see_angle * pressure + (1 - pressure) * b.angle;
          }

        }
        
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="birds" key="birds"/>
      </div>
    )
  }
}

export default Sketch;
