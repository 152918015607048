import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    LoopingCanvas('donut', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
      },
      (ctx, e) => {
        // loop
        ctx.clearRect(0, 0, e.w, e.h);
        
      }
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="donut"/>
      </div>
    )
  }
}

export default Sketch;
