import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var size = 1;

    var slices = [];

    var temp_speed = Math.random() * 10;


    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.fillStyle = '#01295F';
        ctx.fillRect(0, 0, e.w, e.h);
        
       
        ctx.font = "bold 300px sans-serif";
        ctx.textAlign = "center";
        ctx.fillStyle = '#716969';
        ctx.fillText("forget", e.w / 2, e.h / 2 + 100);

        for(var i = 0; i < e.h; i += size){
          slices.push(ctx.getImageData(0, i, e.w, size));
        }

      },
      (ctx, e) => {
        // loop
        ctx.fillStyle = '#01295F';
        ctx.fillRect(0, 0, e.w, e.h);

        var speed = 10 * (1 - e.mouseX / e.w);

        if(!speed){
          speed = temp_speed;
        } else {
          temp_speed = Math.random() * 10;
        }

        for(var i in slices){
          ctx.putImageData(slices[i], 30 * Math.cos(e.frameCounter / 20 + parseInt(i) / speed), i*size);
        }
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
