import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

const config = {
  world_grid_size: 5,
  gravity: .6,
  max_vy: 8
}


var key = {
  left: false,
  right: false,
  up: false,
  down: false
}

var color_code = {
  ['1']: {
    name: 'land',
    r: 255,
    g: 255,
    b: 255
  },
  ['2']: {
    name: 'platform',
    r: 100,
    g: 100,
    b: 100
  },
  ['3']: {
    name: 'death',
    r: 255,
    g: 0,
    b: 0
  },
}

var creepers = [];

var grid_changed = true;
var grid = [];
var grid_cache;

var user;

const getWorldMaterial = (p) => {
  for(var i in color_code){
    var c = color_code[i];
    if(c.r == p.r && c.g == p.g && c.b == p.b){
      return c.name;
    }
  }
}

class Sketch extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){

    LoopingCanvas('platformer', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);

        for(var y = 0; y < e.h; y += config.world_grid_size){
          var new_row = [];
          for(var x = 0; x < e.w; x += config.world_grid_size){
            if(y >= e.h - config.world_grid_size * 3){
              new_row.push(1);
            } else {
              new_row.push(0);
            }
          }
          grid.push(new_row);
        }
        

        user = new Character({
          loc: {
            x: 20, 
            y: e.h - 100
          },
          sprite: {
            w: 8,
            h: 30,
            fill: '#0f0'
          }
         });
      },
      (ctx, e) => {



        // draw surface collision model
        if(grid_changed){
          // clear screen

          ctx.fillStyle = '#000';
          ctx.fillRect(0, 0, e.w, e.h);

          for(var y in grid){
            for(var x in grid[y]){
              // ctx.fillStyle = grid[y][x] ? '#fff' : '#000';
              if(grid[y][x]){
                var c = color_code[grid[y][x]];
                if(c){
                  ctx.fillStyle = 'rgba(' + c.r + ',' + c.g + ',' + c.b + ',1)';
                  ctx.fillRect(x * config.world_grid_size, y * config.world_grid_size, config.world_grid_size, config.world_grid_size);
                }
              }
            }
          }
          grid_cache = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
          grid_changed = false;
          console.log('new cache set');
        } else {
          ctx.putImageData(grid_cache, 0, 0);
        }
        
        if(key.c){
          console.log(e.func.getColorAt(e.mouseX, e.mouseY));
        }


        for(var i in creepers){
          creepers[i].applyPhysics(e);
          if(creepers[i].about_to_fall_left || creepers[i].about_to_fall_right || creepers[i].collision_left  || creepers[i].collision_right){
            creepers[i].v.auto_direction *= -1;
          }
          creepers[i].v.x = creepers[i].v.auto_direction * .5;
          creepers[i].move();
          creepers[i].draw(ctx);          
        }


        user.move();
        user.applyControls(key);
        user.applyPhysics(e);
        user.draw(ctx);



        // switch keys from true to hold
        var key_list = Object.keys(key);
        for(var i in key_list){
          if(key_list[i] === true) key_list[i] = 'hold';
        }


        var key_w = 30;
        var key_h = 15; 
        var key_pad = 5;
        var key_loc_x = key_pad + key_pad;
        var key_loc_y = key_pad + key_pad*2 + key_h * 2;
        // draw keyboard
        ctx.fillStyle =  key.left ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x, key_loc_y - key_h, key_w, key_h);

        ctx.fillStyle =  key.down ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w, key_loc_y - key_h, key_w, key_h);

        ctx.fillStyle =  key.up ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w, key_loc_y - key_h - key_pad - key_h, key_w, key_h);


        ctx.fillStyle =  key.right ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.1)';
        ctx.fillRect(key_loc_x + key_pad + key_w + key_pad + key_w, key_loc_y - key_h, key_w, key_h);
      }
    );

    document.getElementById('platformer').addEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').addEventListener("keyup", this.keyListenerUp, false);
    document.getElementById('platformer').focus();
  }

  componentWillUnmount(){
    document.getElementById('platformer').removeEventListener("keydown", this.keyListenerDown, false);
    document.getElementById('platformer').removeEventListener("keyup", this.keyListenerUp, false);
  }

  keyListenerDown(e){
    // console.log(e);

    switch(e.key){
      case 'ArrowUp':
        key.up = true;
        e.preventDefault();
        break;
      case 'ArrowDown':
        key.down = true;
        e.preventDefault();
        break;
      case 'ArrowLeft':
        key.left = true;
        break;
      case 'ArrowRight':
        key.right = true;
        break;

      default:
        key[e.key] = true;  
        break;
    }
  }

  keyListenerUp(e){
    // console.log(e);

    switch(e.key){
      case 'ArrowUp':
        key.up = false;
        break;
      case 'ArrowDown':
        key.down = false;
        break;
      case 'ArrowLeft':
        key.left = false;
        break;
      case 'ArrowRight':
        key.right = false;
        break;

      default:
        key[e.key] = false;  
        break;
    }
  }


  handleMove(e){

    var el_bounds = document.getElementById('platformer').getBoundingClientRect();

    var grid_cell_x = Math.floor((e.pageX + el_bounds.left) / config.world_grid_size);
    var grid_cell_y = Math.floor((e.pageY + el_bounds.top) / config.world_grid_size); // - 100 idk why
    
    for(var tx = -1; tx < 1; tx++){
      for(var ty = -1; ty < 1; ty++){
        if(grid[grid_cell_y + ty] !== undefined){
          if(grid[grid_cell_y + ty][grid_cell_x + tx] != undefined){
            for(var i = 0; i < 10; i++){
              if(key[i + '']){
                grid[grid_cell_y + ty][grid_cell_x + tx] = i;
                grid_changed = true;
              }
            }
          }
        }
      }
    }
  }

  handleClick(e){

    creepers.push(new Character({
      loc: {
        x: e.pageX, 
        y: e.pageY
      },
      sprite: {
        w: 15,
        h: 15,
        fill: '#f00'
      }
    }))

    // var grid_cell_x = Math.floor(e.pageX / config.world_grid_size);
    // var grid_cell_y = Math.floor((e.pageY) / config.world_grid_size); // - 100 idk why

    // if(grid[grid_cell_y][grid_cell_x] === 0){
    //   grid[grid_cell_y][grid_cell_x] = 1;
    // } else {
    //   grid[grid_cell_y][grid_cell_x] = 0;
    // }
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="platformer" tabIndex="0" autoFocus onMouseMove={this.handleMove} onClick={this.handleClick}/>
      </div>
    )
  }
}


class Character{

  constructor(args){
    if(!args) args = {};

    this.loc = args.loc || {
      x: 0,
      y: 0
    }

    this.v = args.v || {
      x: 0,
      y: 0,
      auto_direction: 1
    }

    this.sprite = args.sprite || {
      w: 5,
      h: 25
    }

    this.landed = false;

    this.jump_velocity_minimum = 5;
    this.jump_velocity_continued = 1;
    this.jump_height_counter = 0;
    this.jump_affected_frames = 5;
    this.landed_speed = 1;
    this.air_speed = .4;

    this.landed = false;
    this.landed_friction = .8;
    this.air_friction = .9;
    this.collision_friction = .2;

  }

  automove(vx){
    this.vx = vx * this.v.auto_direction;
  }

  move(){
    this.loc.x += this.v.x;
    this.loc.y += this.v.y;
  }

  die(){
    this.loc.x = 20;
    this.loc.y = 50;
  }

  applyPhysics(e){

    // check running into left surface
    var left_collision_pattern = '';
    var left_hit = false;
    var left_hit_can_climb = false;
    for(var i = this.loc.y; i <= this.loc.y + this.sprite.h; i += this.sprite.h / 10){
      var p = e.func.getColorAt(this.loc.x, parseInt(i));
      if(getWorldMaterial(p) === 'death') return this.die();
      if(getWorldMaterial(p) === 'land' || getWorldMaterial(p) === 'platform'){
        left_collision_pattern += '1';
      } else {
        left_collision_pattern += '0';
      }
    }

    if(left_collision_pattern.match(/^1[0]+$/g)){
      // console.log('ceiling only');
      // no hit, just collided with the ceiling
    } else if(left_collision_pattern.match(/^[0]*1{6,}$/g)){
      // hit and blocked
      left_hit = true;
      // console.log('lower collision');
    } else if(left_collision_pattern.match(/^[0]+1+[0]+1*$/g)){
      // hit and blocked
      left_hit = true; 
      // console.log('mid collision');
    } else if(left_collision_pattern.match(/^1+[0]+1*$/g)){
      // hit and blocked
      left_hit = true; 
      // console.log('upper collision');
    } else if(left_collision_pattern.match(/^[0]+1{1,5}$/g)){
      left_hit_can_climb = true;
    }

    // check running into right surface
    var right_collision_pattern = '';
    var right_hit = false;
    var right_hit_can_climb = false;
    for(var i = this.loc.y; i <= this.loc.y + this.sprite.h; i += this.sprite.h / 10){
      var p = e.func.getColorAt(this.loc.x + this.sprite.w, parseInt(i));
      if(getWorldMaterial(p) === 'death') return this.die();
      // console.log(p);
      if(getWorldMaterial(p) === 'land' || getWorldMaterial(p) === 'platform'){
        right_collision_pattern += '1';
      } else {
        right_collision_pattern += '0';
      }
    }

    if(right_collision_pattern.match(/^1[0]+$/g)){
      // console.log('ceiling only');
      // no hit, just collided with the ceiling
    } else if(right_collision_pattern.match(/^[0]*1{6,}$/g)){
      // hit and blocked
      right_hit = true;
      // console.log('lower collision');
    } else if(right_collision_pattern.match(/^[0]+1+[0]+1*$/g)){
      // hit and blocked
      right_hit = true; 
      // console.log('mid collision');
    } else if(right_collision_pattern.match(/^1+[0]+1*$/g)){
      // hit and blocked
      right_hit = true; 
      // console.log('upper collision');
    } else if(right_collision_pattern.match(/^[0]+1{1,5}$/g)){
      right_hit_can_climb = true;
    }
    // console.log(right_hit_can_climb);


    this.collision_left = false;
    this.collision_right = false;
    if(left_hit && right_hit){
      // console.log('DOUBLE');
      right_hit_can_climb = true;
      left_hit_can_climb = true;
      // mark these as true to prevent an edge case
      // this is likely a surface above or below
    } else if(left_hit){
      if(this.v.x < 0){
        this.loc.x -= this.v.x * this.collision_friction;
        this.v.x = -this.v.x * this.collision_friction;
        // console.log('left collision');
        // console.log(left_collision_pattern);
        this.collision_left = true;
      }
    } else if(right_hit){
      if(this.v.x > 0){
        this.loc.x -= this.v.x * this.collision_friction;
        this.v.x = -this.v.x * this.collision_friction;
        // console.log('right collision');
        // console.log(right_collision_pattern);
        this.collision_right = true;
      }
    }
    // console.log(left_collision_pattern, right_collision_pattern);


    this.landed = false;
    // console.log(left_hit_can_climb, right_hit_can_climb);
    var p;
    var boundary_to_check;
    if(right_hit_can_climb){
      p = e.func.getColorAt(this.loc.x + this.sprite.w, this.loc.y + this.sprite.h);
      boundary_to_check = this.loc.y + this.sprite.h;

      while(getWorldMaterial(p) === 'land' || (this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
        
        // if(getWorldMaterial(p) === 'land'){
          if(this.v.y >= 0){
            this.loc.y = Math.floor((boundary_to_check) / config.world_grid_size) * config.world_grid_size - this.sprite.h;
          }
        // } else if((this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
        //   if(this.v.y > 0){
        //     this.loc.y = Math.floor((boundary_to_check) / config.world_grid_size) * config.world_grid_size - this.sprite.h;
        //   }
        // }
        this.landed = true;
        boundary_to_check -= config.world_grid_size;

        p = e.func.getColorAt(this.loc.x + this.sprite.w, boundary_to_check);
      } 

    } else if(left_hit_can_climb){
      p = e.func.getColorAt(this.loc.x, this.loc.y + this.sprite.h);
      boundary_to_check = this.loc.y + this.sprite.h;

      while(getWorldMaterial(p) === 'land' || (this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
        
        // if(getWorldMaterial(p) === 'land'){
          if(this.v.y >= 0){
            this.loc.y = Math.floor((boundary_to_check) / config.world_grid_size) * config.world_grid_size - this.sprite.h;
          }
        // } else if((this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
        //   if(this.v.y > 0){
        //     this.loc.y = Math.floor((boundary_to_check) / config.world_grid_size) * config.world_grid_size - this.sprite.h;
        //   }
        // }
        this.landed = true;
        boundary_to_check -= config.world_grid_size;
        
        p = e.func.getColorAt(this.loc.x, boundary_to_check);
      } 
    }


    // var p = e.func.getColorAt(this.loc.x + this.sprite.w/2, this.loc.y + this.sprite.h);
    // if(getWorldMaterial(p) === 'death') return this.die();

  
    // var boundary_to_check = this.loc.y + this.sprite.h;
    // this.landed = false;
    
    // while(getWorldMaterial(p) === 'land' || (this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
      
    //   p = e.func.getColorAt(this.loc.x + this.sprite.w/2, boundary_to_check);
    //   if(getWorldMaterial(p) === 'land' || (this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
    //     if(this.v.y >= 0){
    //       if(right_hit_can_climb || left_hit_can_climb){
    //         this.loc.y = Math.floor((boundary_to_check) / config.world_grid_size) * config.world_grid_size - this.sprite.h;
    //       }
    //     }
    //     this.landed = true;
    //     boundary_to_check -= config.world_grid_size;
    //   } else {
    //     break;
    //   }
    // } 


    // check jumping up into a surface
    var boundary_to_check = this.loc.y;
    var p = e.func.getColorAt(this.loc.x + this.sprite.w/2, this.loc.y);
    if(getWorldMaterial(p) === 'death') return this.die();
    
    while(getWorldMaterial(p) === 'land'){
      p = e.func.getColorAt(this.loc.x + this.sprite.w/2, boundary_to_check);
      if(getWorldMaterial(p) === 'land'){
        this.loc.y = Math.ceil((boundary_to_check) / config.world_grid_size) * config.world_grid_size;
        this.v.y = 0;
        boundary_to_check += config.world_grid_size;
      } else {
        break;
      }
    } 

  

    // get height above next item
    var found_below_left = false;
    this.height_above_left = 0;
    while(!found_below_left){
      var p = e.func.getColorAt(this.loc.x + this.sprite.w/2, this.loc.y + this.sprite.h + this.height_above_left);

      if(getWorldMaterial(p) === 'land' || (this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
        found_below_left = true;
      }
      
      if(found_below_left){
        break;
      }
        
      this.height_above_left++;
      if(this.height_above_left > e.h) break;
    }

    var found_below_right = false;
    this.height_above_right = 0;
    while(!found_below_right){
      var p = e.func.getColorAt(this.loc.x + this.sprite.w/2, this.loc.y + this.sprite.h + this.height_above_right);

      if(getWorldMaterial(p) === 'land' || (this.ignore_platforms ? false : getWorldMaterial(p) === 'platform')){
        found_below_right = true;
      }
      
      if(found_below_right){
        break;
      }
        
      this.height_above_right++;
      if(this.height_above_right > e.h) break;
    }    

    // check about to fall off edges
    this.about_to_fall_left = false;
    this.about_to_fall_right = false;

    var pl = e.func.getColorAt(this.loc.x, this.loc.y + this.sprite.h);
    var pr = e.func.getColorAt(this.loc.x + this.sprite.w, this.loc.y + this.sprite.h);
    if(getWorldMaterial(pl) === 'death' || getWorldMaterial(pr) === 'death') return this.die();
    if(!getWorldMaterial(pl) && (getWorldMaterial(pr) === 'land' || getWorldMaterial(pr) === 'platform')){
      if(this.v.x < 0 && this.height_above_left > this.sprite.h){
        this.about_to_fall_left = true;
      }
    }
    if(!getWorldMaterial(pr) && (getWorldMaterial(pl) === 'land' || getWorldMaterial(pl) === 'platform')){
      if(this.v.x > 0 && this.height_above_right > this.sprite.h){
        this.about_to_fall_right = true;
      }
    }


    if(this.landed){
      this.v.x *= this.landed_friction;
      if(this.v.y > 0){
        this.v.y = 0;
      }
    } else {
      this.v.x *= this.air_friction;
      this.v.y += config.gravity;

      if(this.v.y > config.max_vy) this.v.y = config.max_vy;
    }
  }


  applyControls(key){
    this.ignore_platforms = false;
    if(key.down) this.ignore_platforms = true;

    if(this.landed){
      this.jump_height_counter = 0;

      // landed = true;
      if(key.left){
        this.v.x -= this.landed_speed;
      }
      if(key.right){
        this.v.x += this.landed_speed;
      }
    } else {
      // landed = false;
      if(key.left){
        this.v.x -= this.air_speed;
      }
      if(key.right){
        this.v.x += this.air_speed;
      }
    }

    if(this.jump_height_counter < this.jump_affected_frames && key.up){
      if(this.jump_height_counter === 0){
        this.v.y = -this.jump_velocity_minimum;  
      } else {
        this.v.y += -this.jump_velocity_continued;
      }
      this.jump_height_counter++;
    }
  }

  draw(ctx){
    ctx.fillStyle = this.sprite.fill;
    ctx.beginPath();
    ctx.moveTo(this.loc.x, this.loc.y + this.sprite.h);
    ctx.lineTo(this.loc.x + this.v.x * 2, this.loc.y);
    ctx.lineTo(this.loc.x + this.v.x * 2 + this.sprite.w, this.loc.y);
    ctx.lineTo(this.loc.x + this.sprite.w, this.loc.y + this.sprite.h);
    ctx.fill();
  }
}

export default Sketch;



