import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    // var size = 1;

    // var slices = [];

    // var temp_speed = Math.random() * 10;

    var max_w = 30;
    var max_h = 30;
    var counter = 1;

    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, e.w, e.h);
        
       
        ctx.font = "bold 300px sans-serif";
        ctx.textAlign = "center";
        ctx.fillStyle = '#08415C';
        ctx.fillText("GLITCH", e.w / 2, e.h / 2 + 100);

        // for(var i = 0; i < e.h; i += size){
        //   slices.push(ctx.getImageData(0, i, e.w, size));
        // }

      },
      (ctx, e) => {
        // loop
        counter+=.5;
        if(counter > 100) counter = 100;
        for(var i = 0; i < counter; i++){

          var x = Math.random() * e.w;
          var y = Math.random() * e.h;

          var w = Math.random() * max_w;
          var h = Math.random() * max_h;

          if(x + w > e.w) w = e.w - x;
          if(y + h > e.h) h = e.h - y;

          if(w < 1) w = 1;
          if(h < 1) h = 1;

          var slice = ctx.getImageData(x, y, w, h);

          var variance = 20;
          var nx = x + (Math.random() * variance - variance/2);
          var ny = y + (Math.random() * variance - variance/2);

          ctx.putImageData(slice, nx, ny);
        }
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
