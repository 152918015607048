
export const requestArticles = credentials => {
  return {
    type: 'REQUEST_Articles'
  }
}

export const receiveArticlesSuccess = data => ({
  type: 'RECEIVE_ARTICLES_SUCCESS',
  data
})

export const receiveArticlesFail = data => ({
  type: 'RECEIVE_ARTICLES_FAIL',
  data
})

export const tryToFetchArticles = packet => dispatch => {
  dispatch(requestArticles());
  
  return fetch('/api/medium', {
    method: 'get',
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) return json;
    return json.then(Promise.reject.bind(Promise));
  })
  .then(function(json){
    return dispatch(receiveArticlesSuccess(json));
  })
  .catch(e => {
    dispatch(receiveArticlesFail({errors:e}));
  })
}
