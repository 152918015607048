import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){

    var towers = [];
    var numTowers = 10;
    var layerSize = 10;
    var spring = .999;

    for(var i = 0; i < numTowers; i++){
      var x = Math.random() * window.innerWidth;
      var y = Math.random() * window.innerHeight;

      var r = 100 * Math.random() + 10;
      towers.push({
        offset: Math.PI * 2 * Math.random(),
        x: x,
        y: y,
        r: r,
        spring_x: x,
        spring_y: y,
        spring_r: r,
        layers: []
      })
    }

    LoopingCanvas('wobbly-towers',
      (ctx, e) => {
        // ctx.clearRect(0, 0, e.w, e.h);
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, e.w, e.h);
      },
      (ctx, e) => {
        // ctx.clearRect(0, 0, e.w, e.h);
        ctx.fillStyle = 'rgba(255,255,255,.02)';
        ctx.fillRect(0, 0, e.w, e.h);

        var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.putImageData(imageData, 0, 5);


        towers.sort(function(a, b){
          if(a.y > b.y) return -1;
          if(a.y < b.y) return 1;
          return 0;
        })
        
        for(var i in towers){
          var t = towers[i];

          t.spring_x = spring * t.spring_x + (1 - spring) * t.x;
          t.spring_y = spring * t.spring_y + (1 - spring) * t.y;
          t.spring_r = spring * t.spring_r + (1 - spring) * t.r;
          if(Math.random() > .995){
            t.x = Math.random() * e.w;
            t.y = Math.random() * (e.h - 100) + 100;
            t.r = Math.random() * 100 + 10;
          }


          // console.log(t.spring_y);

          var r = t.spring_y / 10 + 10;

          var sine_r = Math.sin(e.frameCounter / 100 + t.offset) * r/3 + r;


          ctx.fillStyle = 'rgba(255,255,255,.2)';
          ctx.strokeStyle = 'hsl(202,' + r*2 + '%,' + (55 + (e.h - t.spring_y)/20) + '%)';
          ctx.lineWidth = r / 10 + 5;
          ctx.beginPath();
          ctx.ellipse(t.spring_x + Math.sin(e.frameCounter / 20 + t.offset) * (r) / 2, t.spring_y, sine_r, sine_r/3, 0, 0, Math.PI * 2);
          ctx.fill();
          ctx.stroke();
        }
      })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="wobbly-towers" key="wobbly-towers"/>
      </div>
    )
  }
}

export default Sketch;
