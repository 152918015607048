import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    
    var points = [];
    let spring = 0.001;
    let gravity = 0.00;
    let friction = 0.9;


    LoopingCanvas('confetti', 
      (ctx, e) => {
        // optional initialize
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, e.w, e.h);

        var r = 80;
        var spacing = r;
        var padding_w = (e.w % spacing) / 2;
        var padding_h = (e.h % spacing) / 2;

        var numPoints = (e.w + e.h) / 40;

        ctx.lineCap = 'round';

        var t = 30;

        for(var i = 0; i < numPoints; i++){
          var x = Math.random() * e.w;
          var y = Math.random() * e.h;

          points.push({
            color: 'rgba(' + 255 * (x/e.w)  + ',0,' + 255 * (y/e.h) + ',1)',
            color_transparent: 'rgba(' + 255 * (x/e.w)  + ',0,' + 255 * (y/e.h) + ',0)',
            hue: parseInt(Math.random() * 360),
            x: x,
            y: y,
            vx: 0,
            vy: 0,
            r: r
          });
        }

      },
      (ctx, e) => {
        // loop
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillRect(0, 0, e.w, e.h);
        
        // var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        // ctx.putImageData(imageData, 0, 1);

        var dot_d = 20;

        for(var i = 0; i < points.length; i++){
          var p = points[i];

          p.vy += gravity;

          p.x += p.vx;
          p.y += p.vy;

          if(p.x - dot_d/2 < 0){
            p.vx *= -1 * friction;
            p.x = dot_d/2;
          }
          if(p.x + dot_d/2 > e.w){
            p.vx *= -1 * friction;
            p.x = e.w - dot_d/2;
          }
          if(p.y - dot_d/2 < 0){
            p.vy *= -1 * friction;
            p.y = dot_d/2;
          }
          if(p.y + dot_d/2 > e.h){
            p.vy *= -1 * friction;
            p.y = e.h - dot_d/2;
          }


          for(var j = i + 1; j < points.length; j++){
            var o = points[j];
                 
            let dx = o.x - p.x;
            let dy = o.y - p.y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            let minDist = o.r + p.r;
            
            if(distance < minDist) {
              let angle = Math.atan2(dy, dx);
              let targetX = p.x + Math.cos(angle) * minDist;
              let targetY = p.y + Math.sin(angle) * minDist;
              let ax = (targetX - o.x) * spring;
              let ay = (targetY - o.y) * spring;
              p.vx -= ax;
              p.vy -= ay;
              o.vx += ax;
              o.vy += ay;

              // console.log(distance/minDist);
              ctx.beginPath();
              ctx.moveTo(p.x, p.y);
              ctx.lineTo(o.x, o.y);
              ctx.lineWidth = dot_d;
              ctx.strokeStyle = 'hsla(' + (o.hue + p.hue) + ',90%,50%,' + (1 - distance / minDist).toFixed(2) + ')';
              ctx.stroke();
            }
          }
        }
    
        // var blur = 1;
        // ctx.lineWidth = 10;
        // for(var i in points){
        //   ctx.beginPath();
        //   ctx.ellipse(points[i].x, points[i].y, points[i].r, points[i].r, 0, 0, 2 * Math.PI);
        //   ctx.strokeStyle = points[i].color;
        //   ctx.stroke();
        // }
      }
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="confetti"/>
      </div>
    )
  }
}

export default Sketch;
