import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
import SketchManager from '../components/SketchManager/SketchManager.js'

import { toggleSideMenu } from '../actions/actions.export.js'

var countingDownInterval;

class Home extends Component {
  constructor(props){
    super(props)

    this.state = {
      seconds: 5,
      reload: 0
    }
  }

  componentDidMount(){
    const { dispatch } = this.props;
    // if(window.innerWidth > 550){
      dispatch(toggleSideMenu(true));
    // }

    countingDownInterval = setInterval((e)=>{
      var s = this.state.seconds - 1;
      var r = this.state.reload;
      if(s === 0){
        s = 5;
        r++;
      }

      this.setState({
        seconds: s,
        reload: r
      })
    }, 1000);
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="body-stretch" key={this.state.r}>
      
      <div className="screen-height-container">
        <Header/>
        <div className="shc-background">
           <SketchManager reload={this.state.reload}/>
        </div>
        <div className="shc-content">
          <div className="flex-center-column">
            
          </div>
        </div>

        <div className="shc-bottom-content">
          <Footer/>
        </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(Home);