import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    var numRects = 100;
    var rects = [];
    var spring = .95;
    var maxCounter = 1;
    var padding = 0;

    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);

        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fillRect(0, 0, e.w, e.h);
          
        for(var i = 0; i < numRects; i++){
          rects.push({
            w: (e.w - padding * 2) / numRects,
            sw: (e.w - padding * 2) / numRects,
            counter: 1
          })
        }
      },
      (ctx, e) => {
        // loop
        // ctx.clearRect(0, 0, e.w, e.h);


        // ctx.fillStyle = 'rgba(255,255,255,.02)';
        // ctx.fillRect(0, 0, e.w, e.h);

        var n = 10;
        var direction = 1;
        for(var i = 0; i < e.w; i+=n){
          var imageData = ctx.getImageData(i, 0, n, ctx.canvas.height);
          ctx.putImageData(imageData, i, direction);          
          direction *= -1;
        }

        // var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        // ctx.putImageData(imageData, 0, -1);


        if(e.frameCounter % 2 === 0){
          var r_index = parseInt(Math.random() * rects.length);
          var r = rects[r_index];
          var new_w = Math.random() * 500;
          var to_distribute = new_w - r.w;

          // console.log(to_distribute, (to_distribute) / (numRects - 1));
          
          r.w = new_w;
          r.counter++;

          if(r.counter > maxCounter) maxCounter = r.counter;


          var left_overs = 0;
          for(var i = 0 ; i < rects.length; i++){
            if(i !== r_index){
              rects[i].w -= (to_distribute) / (numRects - 1);
              if(rects[i].w < 0){
                left_overs += -rects[i].w;
                rects[i].w = 0;
              } 
            }
          }
          // console.log(left_overs);
          rects[r_index].w -= left_overs;

        }


        ctx.fillStyle = '#fff';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 1;
        var x = (padding);
        var h = e.h/2;
        for(var i = 0 ; i < rects.length; i++){
          // if(i !== r_index){
          rects[i].sw = rects[i].sw * spring + rects[i].w * (1 - spring);
          // ctx.fillStyle = 'hsla(' + parseInt(60 * i/numRects) + ',75%,50%,.15)';
          // ctx.strokeStyle = 'hsla(' + parseInt(60 * i/numRects) + ',75%,50%,1)';
          // ctx.fillRect(x, e.h - h + padding  - h * rects[i].counter / maxCounter / 2, rects[i].sw, h * rects[i].counter / maxCounter);
          // ctx.strokeRect(x, e.h - h + padding  - h * rects[i].counter / maxCounter / 2, rects[i].sw, h * rects[i].counter / maxCounter);
          ctx.beginPath();
          ctx.arc(x + rects[i].sw/2, e.h/2, rects[i].sw/2, rects[i].sw/2, 0, Math.PI * 2);
          ctx.fill();
          ctx.stroke();
          
          x += rects[i].sw;
        }
      }
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
