import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var linesCount = 1000;

    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
      },
      (ctx, e) => {
        // loop
        ctx.fillStyle = '#FFBA08';
        ctx.fillRect(0, 0, e.w, e.h);
        
        ctx.strokeStyle = 'rgba(0,0,0,.5)';
        // ctx.lineWidth = 3;
        for(var i = 0; i < linesCount; i++){

          var r = 300 * Math.random();
          ctx.beginPath();
          ctx.moveTo(e.w/2, e.h/2);
          ctx.lineTo(e.w/2 + r * Math.cos(i/linesCount * Math.PI * 2), e.h/2 + r * Math.sin(i/linesCount * Math.PI * 2))
          ctx.stroke();
        }

        var r = Math.sin(e.frameCounter / 60) * 150 + 150;
        ctx.fillStyle = '#FFBA08';
        ctx.beginPath();
        ctx.arc(e.w/2, e.h/2, r, r, 0, Math.PI * 2);
        ctx.fill();
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
