

const LoopingCanvas = (id, initialize, drawLoop) => {
  var w;
  var h;
  var canvas;
  var dimensions;
  var ctx;
  var frameCounter = 0;
  var mouseX;
  var mouseY;


  const resize = (canvas) => {
    dimensions = canvas.getBoundingClientRect();
    w = dimensions.right - dimensions.left;
    h = dimensions.bottom - dimensions.top;
    canvas.setAttribute('width', w);
    canvas.setAttribute('height', h);
  }

  canvas = document.getElementById(id);
  resize(canvas);

  // get ctx and clear it
  ctx = canvas.getContext("2d");
  ctx.resetTransform();
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  
  ctx.fillStyle = '#000';
  ctx.fillRect(0, 0, w, h);

  if(drawLoop === undefined){
    drawLoop = initialize;
    console.log(id + ' has no custom initialization.');
  } else {
    console.log(id + ' has been initialized.');
    initialize(ctx, {w: w, h: h});
  }

  const loop = () => {    

    drawLoop(ctx, {
      w: w,
      h: h,
      mouseX: mouseX,
      mouseY: mouseY,
      frameCounter: frameCounter,
      func: {
        getColorAt: getColorAt
      }
    });

    frameCounter++;

    if(document.getElementById(id)){
      requestAnimationFrame(loop);
    } else {
      console.log(id + ' has been removed.');
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }

  const handleResize = (e) => {
    resize(canvas);
  }

  const handleMouseMove = (e) => {
    mouseX = e.clientX;
    mouseY = e.clientY;
  }

  const getColorAt = (x,y) => {
    var d = ctx.getImageData(x, y, 1, 1).data; 
    return {
      r: d[0],
      g: d[1],
      b: d[2],
      a: d[3]
    }
  }

  window.addEventListener('resize', handleResize);
  window.addEventListener('mousemove', handleMouseMove);

  
  loop();
  console.log(id + ' loop started.');

  return this;
}



export default LoopingCanvas;