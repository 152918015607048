export const globalReducer = (state = {
  test: true,
  sidemenu: {
    open: false
  }
}, action) => {

  switch(action.type){
    case 'TEST_ACTION':

      return {
        ...state
      }

    case 'TOGGLE_SIDE_MENU': 

      if(action.data !== undefined){
        return {
          ...state, 
          sidemenu: {
            open: action.data
          }
        }
      } else {
        return {
          ...state,
          sidemenu: {
            open: !state.sidemenu.open
          }
        }
      }
      
    default:
      return state
  }
}