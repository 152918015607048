import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';

import './SketchManager.css';

var sketches = require('../../sketches/_list.js').default;
var Sketch;

class SketchManager extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.loadSketch = this.loadSketch.bind(this);
  }

  componentWillMount(){
    
    this.loadSketch();
  }

  loadSketch(){
    const { dispatch } = this.props;
    
    var sketch_id = this.props.sketch;

    var found = false;
    for(var i in sketches){
      if(sketches[i].id === sketch_id){
        found = true;
        break;
      }
    }

    if(!found && sketch_id != 'random' && sketch_id != 'latest' && sketch_id){
      // try{
        Sketch = require('../../sketches/' + sketch_id + '.js').default;
      // } catch(e){
      //   console.error(e);
      //   Sketch = require('../../sketches/' + sketches[0].id + '.js').default;
      //   return dispatch(push('/sketches')); 
      // }
    } else if(sketch_id === 'latest'){
      sketch_id = sketches[0].id;
    } else if(sketch_id === 'random' || !this.props.sketch){
      let filteredSketches = sketches.filter(s => !s.noHomePage);
      sketch_id = filteredSketches[parseInt(Math.random() * filteredSketches.length)].id;
    }

    // try{
      Sketch = require('../../sketches/' + sketch_id + '.js').default;
    // } catch(e){
    //   console.error(e);
    //   Sketch = require('../../sketches/' + sketches[0].id + '.js').default;
    //   return dispatch(push('/sketches')); 
    // }
  }

  componentWillReceiveProps(){
    this.loadSketch();
  }

  render(){

    return (
      <div className="sketch-manager">
        <div className="sketch-manager-inner">
          <Sketch/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // const { user } = state;
  return {
    // user
  }
}

export default connect(mapStateToProps)(SketchManager);
