import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'

import ScrollToTop from './ScrollToTop.js'

import Home from './routes/Home.js'

import WorkFullScreen from './routes/WorkFullScreen.js'
import WorkList from './routes/WorkList.js'

import SketchFullScreen from './routes/SketchFullScreen.js'
import SketchList from './routes/SketchList.js'

import ToolFullScreen from './routes/ToolFullScreen.js'
import ToolRoute from './routes/ToolRoute.js'

import BrandList from './routes/BrandList.js'
import Contact from './routes/Contact.js'
import Writing from './routes/Writing.js'
import Audio from './routes/Audio.js'
import API from './routes/API.js'

import SideMenu from './components/SideMenu/SideMenu.js'
import Tooltip from './components/Tooltip/Tooltip.js'

import { toggleSideMenu, tryToFetchArticles } from './actions/actions.export.js'


class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.handleSideMenuClose = this.handleSideMenuClose.bind(this);
  }

  handleSideMenuClose(){
    const {globalReducer, dispatch} = this.props;
    if(globalReducer.sidemenu.open) dispatch(toggleSideMenu(false));
  }

  componentWillMount(){
    const { dispatch } = this.props;
    dispatch(tryToFetchArticles());
  }

  render(){
    const { history } = this.props;

    return <ConnectedRouter history={history}>
      <ScrollToTop>
        <div className="body">
          <Tooltip/>
          <SideMenu/>
          <div className="body-wrapper" onClick={this.handleSideMenuClose}>
            <Switch>
              <Route exact path="/" component={Contact}/>

              {/*<Route path="/work/:work_id" component={WorkFullScreen} />*/}
              {/*<Route path="/work" component={WorkList} />*/}

              <Route path="/sketchbook/:sketch_id" component={SketchFullScreen} />
              <Route path="/sketchbook" component={SketchList} />
              {/*<Route path="/writing" component={Writing} />*/}
              {/*<Route path="/api" component={API} />*/}
              {/*<Route path="/audio" component={Audio} />*/}
              <Route path="/brands" component={BrandList} />
              {/*<Route path="/about" component={Contact} />*/}
              <Route path="/tools/:tool_id" component={ToolFullScreen} />
              <Route path="/tools" component={ToolRoute} />
              <Redirect to='/' />
            </Switch>
          </div>
        </div>
      </ScrollToTop>
    </ConnectedRouter>
  }
}

const mapStateToProps = (state) => {
  const { 
    globalReducer
    // nav
    //...
  } = state;

  return {
    globalReducer
    // nav
    //...
  }
}

export default connect(mapStateToProps)(App);