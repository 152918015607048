import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
 

class Writing extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { medium } = this.props;
    const state = this.state;

    return <div className="body-stretch">
      <Header/>

      <div className="container">
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-300">writing</h2>
            <p>
              Occasionally (and when I'm able to publicly) I put thoughts on paper.
            </p>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-12">
            <div className="card-columns">
              {
                medium.articles.map((article, i)=> {
                  
                  var clean = sanitizeHtml(article.content_encoded, {
                    allowedTags: ['img'],
                    allowedTags: false,
                  });
                  return <a href={article.link} target="_blank" key={i} className="card article">
                    <h5 className="article-title">{article.title}</h5>
                    <small>{moment(article.published).format('MMMM YYYY')}</small>
                    <div dangerouslySetInnerHTML={{__html: clean}} className="article-description"/>
                    <div className="article-tags">
                      {
                        article.category.map((t,j)=>{
                          return <span className="article-tag" key={j}>{t}</span>
                        })
                      }
                    </div>
                  </a>
                })
              }
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    medium
  } = state;

  return {
    medium
  }
}

export default connect(mapStateToProps)(Writing);