import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    LoopingCanvas('wall-of-clocks', (ctx, e) => {
      ctx.resetTransform();
      ctx.clearRect(0, 0, e.w, e.h);
      

      var r = 40;
      var spacing = r * 2;
      var padding = 100;
      var padding_w = e.w % spacing / 2;
      var padding_h = e.h % spacing / 2;

      ctx.lineCap = 'round';

      var t = 30;

      for(var lx = 0; (lx + 1) * spacing < e.w - padding_w * 2; lx++){
        for(var ly = 0; (ly + 1) * spacing < e.h - padding_h * 2; ly++){
          var x = (lx + 1) * spacing + padding_w;
          var y = (ly + 1) * spacing + padding_h;

          ctx.resetTransform();
          ctx.translate(x, y);
          
          ctx.lineWidth = 2;
          
          ctx.beginPath();
          ctx.moveTo(0,0);
          var theta = e.frameCounter/t + (x + y) / 250;
          ctx.lineTo(.8 * r * Math.cos(theta), .8 * r * Math.sin(theta));
          ctx.stroke();

          ctx.lineWidth = 3;

          ctx.beginPath();
          ctx.moveTo(0,0);
          var theta = e.frameCounter/(t*12) + (x + y) / 250;
          ctx.lineTo(.6 * r * Math.cos(theta), .6 * r * Math.sin(theta));
          ctx.stroke();
        }
      }
    })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="wall-of-clocks" key="wall-of-clocks"/>
      </div>
    )
  }
}

export default Sketch;
