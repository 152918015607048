
export const medium = (state = {
  articles: []
}, action) => {

  switch(action.type){
    case 'REQUEST_ARTICLES':

      return {
        ...state,
        tryingToGetArticles: true
      }

    case 'RECEIVE_ARTICLES_FAIL':

      return {
        ...state,
        tryingToGetArticles: false
      }

    case 'RECEIVE_ARTICLES_SUCCESS':

      
      let articles = [];
      if(action.data.items){
        articles = action.data.items;
      }
      return {
        ...state,
        articles: articles,
        tryingToGetArticles: false
      }
      
    default:
      return state
  }
}