import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'


import './Footer.css';

class Footer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
  }

  render(){
    const { dispatch, user } = this.props; 
    const state = this.state;

    return (
      <div className="footer">
        <div className="footer-wrapper">
          <div>
            <small className="translucent">
              &copy; {new Date().getFullYear()} Peter Binggeser
            </small>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  // const { user } = state;

  return {
    // user
  }
}

export default connect(mapStateToProps)(Footer);
