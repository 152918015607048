import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){


    function CC(x, y, r){
      this.x = x;
      this.y = y;
      // this.vx = Math.random();
      // this.vy = Math.random();
      this.angle = Math.random() * (Math.PI * 2);
      this.vx = Math.cos(this.angle);
      this.vy = Math.sin(this.angle);

      this.r = r

      return this;
    }

    var circles = []

    var num = 10;

    // var smallest_r = 10;
    var current_r = 300;
    for(var i = 0; i < num; i++){
      circles.push(new CC(window.innerWidth/2, window.innerHeight/2, current_r));
      current_r /= 1.5;
    }

    var points = [];
    var pointFrequency = 30;
    var lastPoint = -pointFrequency;
  

    LoopingCanvas('concentric-squares', (ctx, e) => {
      ctx.clearRect(0, 0, e.w, e.h);

      function drawCurvedLine(points){
        var points = JSON.parse(JSON.stringify(points));
        var next_loc;
        ctx.beginPath();
        ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
        for(var i = 1; i < points.length; i++){
          next_loc = points[i + 1];
          if(!next_loc) continue;

          var xc = (points[i].x + next_loc.x) / 2;
          var yc = (points[i].y + next_loc.y) / 2;
          ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
        }
      }

      if(points.length === 0){
        points.push({
          x: circles[circles.length - 1].x,
          y: circles[circles.length - 1].y
        })
      }

      for(var i in circles){
        var c = circles[i];

        c.x += c.vx * 1;
        c.y += c.vy * 1;

        ctx.strokeStyle = '#000'
        if(i > 0){
          var pc = circles[i-1]

          var bump = false;
          if(c.x - c.r < pc.x - pc.r){
            c.x = c.r + pc.x - pc.r;
            c.vx *= -1;
            bump = true;
          }
          if(c.y - c.r < pc.y - pc.r){
            c.y = c.r + pc.y - pc.r;
            c.vy *= -1;
            bump = true;
          }
          if(c.x + c.r > pc.x + pc.r){
            c.x = pc.x + pc.r - c.r;
            c.vx *= -1;
            bump = true;
          }
          if(c.y + c.r > pc.y + pc.r){
            c.y = pc.y + pc.r - c.r;
            c.vy *= -1;
            bump = true;
          }
          if(bump && i == circles.length - 1){
            points.push({
              x: c.x,
              y: c.y
            })
          }

          // var distance = Math.sqrt(Math.pow(circles[i-1].x - c.x, 2) + Math.pow(circles[i-1].y - c.y, 2));
          // if(distance >= circles[i-1].r - c.r){
          //   var lastx = c.x;
          //   var lasty = c.y;

          //   var a = Math.atan2(circles[i-1].y - c.y,circles[i-1].x - c.x);
          //   c.x = -(circles[i-1].r - c.r) * Math.cos(a) + circles[i - 1].x;
          //   c.y = -(circles[i-1].r - c.r) * Math.sin(a) + circles[i - 1].y;

          //   c.vx = (c.x - lastx) * .4;
          //   c.vy = (c.y - lasty) * .4;
          
          //   if(i == circles.length - 1){
          //     points.push({
          //       x: c.x,
          //       y: c.y
          //     })
          //   }
          // } 
        } else {
          if(c.x - c.r < 0){
            c.x = c.r;
            c.vx *= -1;
          }
          if(c.y - c.r < 0){
            c.y = c.r;
            c.vy *= -1;
          }
          if(c.x + c.r > e.w){
            c.x = e.w - c.r;
            c.vx *= -1;
          }
          if(c.y + c.r > e.h){
            c.y = e.h - c.r;
            c.vy *= -1;
          }
        }

        if(i % 2 == 0){
          ctx.fillStyle = '#000';
        } else {
          ctx.fillStyle = '#fff';
        }
        // ctx.lineWidth = 1;
        ctx.fillRect(c.x - c.r, c.y - c.r, c.r*2, c.r*2);
        // ctx.beginPath();
        // ctx.arc(c.x, c.y, c.r, 0, 2 * Math.PI);
        ctx.stroke();
      }

      if(e.frameCounter - lastPoint > pointFrequency){
        lastPoint = e.frameCounter;
        points.push({
          x: circles[circles.length - 1].x,
          y: circles[circles.length - 1].y
        })
      }

    })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="concentric-squares" key="concentric-squares"/>
      </div>
    )
  }
}

export default Sketch;
