import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var vx = (Math.random() - .5) * 5;
    var vy = (Math.random() - .5) * 5;

    var tx = 0;
    var ty = 0;

    LoopingCanvas('uno', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);

        tx = e.w / 2;
        ty = e.h / 2;
        
      },
      (ctx, e) => {
        // loop
        ctx.clearRect(0, 0, e.w, e.h);

        tx += vx;
        ty += vy;


        if(tx < 0){
          tx = 0;
          vx *= -1;
        }
        if(ty < 0){
          ty = 0;
          vy *= -1;
        }

        if(tx > e.w){
          tx = e.w;
          vx *= -1;
        }
        if(ty > e.h){
          ty = e.h;
          vy *= -1;
        }

        var r = 200;
        for(var x = r; x < e.w; x += r){
          for(var y = r; y < e.h; y += r){
            var distance = Math.sqrt(Math.pow(tx - x, 2) + Math.pow(ty - y, 2));

            ctx.lineWidth = distance > 300 ? 2 : 300 - distance;
            ctx.beginPath();
            ctx.arc(x, y, distance / 75 * r, 0, 2 * Math.PI);
            ctx.strokeStyle = 'rgba(' + parseInt(255 - distance / 100   * r) + ',0,' + parseInt(distance / 1000 * r) + ',1)';
            ctx.stroke();
          }
        }
        
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="uno" key="uno"/>
      </div>
    )
  }
}

export default Sketch;
