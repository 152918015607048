import React, { Component } from 'react'

import LoopingThree from './_.LoopingThree';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var geometry;
    var material;
    var mesh;

    LoopingThree('boilerplate-three', 
      (scene, e) => {
        // optional initialize

        geometry = new e.THREE.BoxGeometry(0.2, 0.2, 0.2);
        material = new e.THREE.MeshPhongMaterial( {
          color: 0xffffff,
          linewidth: 1,
          linecap: 'round', //ignored by WebGLRenderer
          linejoin:  'round' //ignored by WebGLRenderer
        });

        mesh = new e.THREE.Mesh(geometry, material);
        scene.add( mesh );
        
        var light = new e.THREE.PointLight( 0xffffff, 1, 100 );
        light.position.set( 10, 10, 10 );
        scene.add( light );

        var amb_light = new e.THREE.AmbientLight( 0x404040 ); // soft white light
        scene.add( amb_light );

      },
      (scene, e) => {
        // loop

        mesh.rotation.y += 0.02;
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="boilerplate-three"/>
      </div>
    )
  }
}

export default Sketch;
