import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    var lag = [];
    var lastW;
    var lastH;

    LoopingCanvas('sine-dots', (ctx, e) => {
      ctx.clearRect(0, 0, e.w, e.h);
      

      var r = 100;
      var spacing = r/2;
      var padding = 200;

      if(e.w !== lastW || e.h != lastH){
        lastW = e.w;
        lastH = e.h;
        lag = [];

        for(var lx = 0; lx * spacing < e.w - padding * 2; lx++){
          var col = [];
          for(var ly = 0; ly * spacing < e.h - padding * 2; ly++){
            col.push(0)
          }
          lag.push(JSON.parse(JSON.stringify(col)));
        }
      }


      for(var lx = 0; (lx + 1) * spacing < e.w - padding * 2; lx++){
        for(var ly = 0; (ly + 1) * spacing < e.h - padding * 2; ly++){
          var x = (lx + 1) * spacing + padding;
          var y = (ly + 1) * spacing + padding;

          var tr = Math.sin(e.frameCounter/30 + (x + y) / 250 + lag[lx][ly]) * r/2 + (r-4)/2 + 5;
          if(tr < 0) tr = 2;

          ctx.lineWidth = tr/5;
          ctx.beginPath();
          ctx.arc(x, y, tr, 0, 2 * Math.PI);
          ctx.strokeStyle = 'rgba(' + tr * 2 + ',' + tr * 5 + ',' + (0+tr*10) +',1)';
          ctx.stroke();
        }
      }
    })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sine-dots" key="sine-dots"/>
      </div>
    )
  }
}

export default Sketch;
