import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    var lag = [];
    var lastW;
    var lastH;

    LoopingCanvas('sine-lines', (ctx, e) => {
      ctx.clearRect(0, 0, e.w, e.h);
      

      var r = 50;
      var spacing = r;

      if(e.w !== lastW || e.h != lastH){
        lastW = e.w;
        lastH = e.h;
        lag = [];

        for(var lx = 0; lx * spacing < e.w; lx++){
          var col = [];
          for(var ly = 0; ly * spacing < e.h; ly++){
            col.push(Math.random() * 1)
          }
          lag.push(JSON.parse(JSON.stringify(col)));
        }
      }

      function drawCurvedLine(points){
        var points = JSON.parse(JSON.stringify(points));
        var next_loc;
        ctx.beginPath();
        ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
        for(var i = 1; i < points.length; i++){
          next_loc = points[i + 1];
          if(!next_loc) continue;

          var xc = (points[i].x + next_loc.x) / 2;
          var yc = (points[i].y + next_loc.y) / 2;
          ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
        }
      }

      for(var lx = 0; (lx + 1) * spacing < e.w - 100; lx++){
        var points = [];
        
        for(var ly = 0; (ly + 1) * spacing < e.h; ly++){

          var x = (lx + 1) * spacing + 60;
          var y = (ly + 1) * spacing;

          var tr = Math.sin(e.frameCounter/30 + (x + y) / 250 + lag[lx][ly]) * r/2 + (r-4)/2 + 5;
          if(tr < 0) tr = 0;

          points.push({
            x: x + tr - spacing,
            y: y
          })
        }

        ctx.lineWidth = 20;
        ctx.lineCap = 'round';
        // ctx.arc(x, y, tr, 0, 2 * Math.PI);
        ctx.strokeStyle = '#2C514C';
        drawCurvedLine(points);
        // ctx.fillStyle = '#999';
        ctx.stroke();
        // ctx.fill();
      }



    })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sine-lines" key="sine-lines"/>
      </div>
    )
  }
}

export default Sketch;
