import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'

const contactData = {
  email: "peter@binggeser.com",
  website: "peter@binggeser.com",
  twitter: "@peterbinggeser",
  medium: "@peterbinggeser",
}

const mediumExample = `{
    "title": "Stories by Peter Binggeser on Medium",
    "description": "Stories by Peter Binggeser on Medium",
    "link": "https://medium.com/@peterbinggeser?source=rss-1a82bc9546e2------2",
    "image": "https://cdn-images-1.medium.com/fit/c/150/150/1*gCdYSUDnxgXNogOCk3shNw.jpeg",
    "category": [],
    "items": [
        {
            "title": "Data Does Not Have Intrinsic Value",
            "link": "https://towardsdatascience.com/data-does-not-have-intrinsic-value-2824c2409d86?source=rss-1a82bc9546e2------2",
            "author": "Peter Binggeser",
            "published": 1507052091000,
            "created": 1507052091000,
            "category": [
                "technology",
                "data-science",
                "business",
                "strategy",
                "tech"
            ],
            "content": "<p>Combined with mystique, confusion, and general misunderstanding — the phenomenon of data being produced everywhere, all the time, has led to two problematic assumptions:</p><h4>1. Data has value.</h4><h4>2. More data means more value...

...packet truncated
`

const educationPacket = {
    "school": "Illinois Institute of Technology",
    "degree_name": "Bachelor's of Architecture",
    "level": "bachelors",
    "major": "architecture",
    "start_date": "2007-08",
    "end_date": "2012-05"
}

const educationPacketDetails = {
      school: "Illinois Institute of Technology",
      degree_name: "Bachelor's of Architecture",
      level: "bachelors",
      major: "architecture",
      start_date: "2007-08",
      end_date: "2012-05",
      details: [
        'graduated cum laude',
        'digital design specialization',
        'When I attended Illinois Institute of Technology, the acronym of "I.I.T." used to often be confused with ITT Technical Institute, a "school" that ran frequent late night commercials about turning your life around with a new IT career. I did not attend the school from those commercials. In 2016, ITT was thankfully shut down and declared bankruptcy after the United States Department of Education prevented students from using federally guaranteed student loans there. IIT (my alma mater) now mostly goes by "Illinois Tech" instead to continue to distance itself from the institution that scammed so many people and put so many in debt.',
        'A handful of years after graduating I had the pleasure of teaching graduate courses at the Institute of Design (ID), which is my alma mater\'s graduate design school.'
      ]
    }

const experiencePacket = [
    {
        "company": "Twitter Inc",
        "title": "Senior Technical Program Manager (L6)",
        "team": "Twitter Next",
        "start_date": "2017-11",
        "end_date": "2022-11",
        "type": "full_time"
    },
    {
        "company": "Data Mining & Supply LLC",
        "title": "Technical Co-Founder",
        "start_date": "2016-04",
        "end_date": "2017-10",
        "type": "full_time"
    },
    {
        "company": "SapientNitro",
        "title": "Manager",
        "team": "Experience Technology (XT) Studio",
        "start_date": "2012-08",
        "end_date": "2016-03",
        "type": "full_time"
    },
    {
        "company": "Iota Partners LLC",
        "title": "Creative Technologist",
        "start_date": "2011-09",
        "end_date": "2012-08",
        "type": "full_time"
    }
]


const experiencePacketWithFilters = [
    {
        "company": "Data Mining & Supply LLC",
        "title": "Technical Co-Founder",
        "start_date": "2016-04",
        "end_date": "2017-10",
        "type": "full_time"
    },
    {
        "company": "Twitter Inc",
        "title": "Senior Technical Program Manager (L6)",
        "team": "Twitter Next",
        "start_date": "2017-11",
        "end_date": "2022-11",
        "type": "full_time"
    },
    {
        "company": "We All Code",
        "title": "Associate Board Chair & Member",
        "start_date": "2018-03",
        "end_date": "2019-08",
        "type": "volunteer"
    },
    {
        "company": "Not Yet Digital LLC",
        "title": "Founder, Product & Technical Lead",
        "start_date": "2018-06",
        "type": "part_time"
    }
]

const technologies = ['3DS Max', 'AWS', 'Adobe Illustrator', 'Adobe Photoshop', 'App Engine', 'Arduino', 'AutoCAD', 'BigQuery', 'CSS', 'D3', 'Datastore', 'Firestore', 'GitHub', 'Google Cloud Platform', 'Google Docs', 'Grasshopper', 'HTML5', 'Heroku', 'JIRA', 'Java', 'JavaScript', 'Keynote', 'LucidChart', 'Memcached', 'Microsoft Azure', 'Microsoft Office', 'NoSQL', 'Node.js', 'PHP', 'Processing', 'Python', 'React', 'Redis', 'Redux', 'Rhino', 'S3', 'SASS', 'SQL', 'Sheets', 'Slides', 'Stripe', 'Trello'];

class API extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;



    return <div className="body-stretch">
      <Header/>

      <div className="container sketch-list">
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-300">API</h2>
            <p>
              I'm a little tired of resumes on portoflio sites, so here is mine as an API instead.
            </p>
            <p>
              All requests URLs should leverage my domain as the root and use HTTPS. 
            </p>
            For example: <code>https://peter.binggeser.com/api/contact</code>
          </div>
        </div>
        <hr/>
        
        <div className="row row-push-down-extra">
          <div className="col-md-12">
            <h2><code><span className="text-700">GET</span> <a target="_blank" href="/api/contact">/api/contact</a></code></h2>
            <p>Get an object consisting of my most recent contact information.</p>
            <small>Example response:</small>
            <pre>{JSON.stringify(contactData,null,2)}</pre>
            <p>You can also pass a <code>method</code> query parameter to just request a single specific contact method:</p>
            <div><code><span className="text-700">GET</span> <a target="_blank" href="/api/contact?method=email">/api/contact?<strong>method=email</strong></a></code></div>
            <pre>{JSON.stringify(contactData.email,null,2)}</pre>

            <p>If you ask for something beyond what I want to share, it'll tell you of course:</p>
            <div><code><span className="text-700">GET</span> <a target="_blank" href="/api/contact?method=SSN">/api/contact?<strong>method=SSN</strong></a></code></div>
            <pre>{"I don't share that."}</pre>
          </div>
        </div>


        <div className="row row-push-down">
          <div className="col-md-12">
            <h2><code><span className="text-700">GET</span> <a target="_blank" href="/api/experience">/api/experience</a></code></h2>
            <p>Request information about my work experience.</p>
            <small>Example response:</small>
            <pre>{JSON.stringify(experiencePacket,null,2)}</pre>

            <p>Mix and match <code>include_part_time</code>, <code>include_volunteer</code>, <code>from</code>, <code>to</code>, and <code>order</code> parameters to filter and sort based on time using YYYY-MM format:</p>
            <div><code><span className="text-700">GET</span> <a target="_blank" href="/api/experience?from=2017-01&to=2019-01&order=ASC&include_volunteer=true&include_part_time=true">/api/experience?<strong>from=2017-01&to=2019-01&order=ASC&include_volunteer=true&include_part_time=true</strong></a></code></div>
            <pre>{JSON.stringify(experiencePacketWithFilters,null,2)}</pre>
          </div>
        </div>



        <div className="row row-push-down">
          <div className="col-md-12">
            <h2><code><span className="text-700">GET</span> <a target="_blank" href="/api/education">/api/education</a></code></h2>
            <p>Request information about my degree.</p>
            <small>Example response:</small>
            <pre>{JSON.stringify(educationPacket,null,2)}</pre>

            <p>Use <code>details=true</code> query parameter to get more information:</p>
            <div><code><span className="text-700">GET</span> <a target="_blank" href="/api/education?details=true">/api/education?<strong>details=true</strong></a></code></div>
            <pre>{JSON.stringify(educationPacketDetails,null,2)}</pre>
          </div>
        </div>


        <div className="row row-push-down">
          <div className="col-md-12">
            <h2><code><span className="text-700">GET</span> <a target="_blank" href="/api/technologies">/api/technologies</a></code></h2>
            <p>List technolgies and software that I've worked with in the past, likely not collectively exhaustive but it's still maybe useful to share.</p>
            <small>Example response:</small>
            <pre>{JSON.stringify(technologies)}</pre>
            <p>Pass a <code>but_do_you_know</code> query parameter to check a specific technology if you don't feel like combing through the list:</p>
            <div><code><span className="text-700">GET</span> <a target="_blank" href="/api/technologies?but_do_you_know=react">/api/technologies?<strong>but_do_you_know=react</strong></a></code></div>
            <pre>{JSON.stringify("yes")}</pre>
            <div><code><span className="text-700">GET</span> <a target="_blank" href="/api/technologies?but_do_you_know=reeeeeeact">/api/technologies?<strong>but_do_you_know=reeeeeeact</strong></a></code></div>
            <pre>{JSON.stringify("Potentially no, but it's possible I just forgot to include it or spelled it differently.")}</pre>
          </div>
        </div>


        <div className="row row-push-down">
          <div className="col-md-12">
            <h2><code><span className="text-700">GET</span> <a target="_blank" href="/api/medium">/api/medium</a></code></h2>
            <p>A JSONified version of the RSS feed of my articles on Medium.</p>
            <small>Example response:</small>
            <pre>{mediumExample}</pre>
          </div>
        </div>


        <div className="row row-push-down">
          <div className="col-md-12">
            <h2><code><span className="text-700">GET</span> <a target="_blank" href="/api/is-this-some-kind-of-a-joke">/api/is-this-some-kind-of-a-joke</a></code></h2>
            <small>Example response:</small>
            <pre>{JSON.stringify("yes",null,2)}</pre>
          </div>
        </div>
        
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(API);