import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var points = [];
    let spring = 0.001;
    let gravity = 0.00;
    let friction = 0.9;

    LoopingCanvas('hello-dots', 
      (ctx, e) => {
        // optional initialize
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, e.w, e.h);

        var r = 100;
        var spacing = r * 2;
        var padding_w = (e.w % spacing) / 2;
        var padding_h = (e.h % spacing) / 2;


        ctx.lineCap = 'round';

        var t = 30;

        for(var ly = 0; ly * spacing < e.h - padding_h * 2; ly++){

          for(var lx = 0; lx * spacing < e.w - padding_w * 2; lx++){
            var x = (lx + .5) * spacing + padding_w;
            var y = (ly + .5) * spacing + padding_h;

            points.push({
              color: 'rgba(' + 255 * (x/e.w)  + ',0,' + 255 * (y/e.h) + ',1)',
              x: x + Math.random() * 100,
              y: y + Math.random() * 100,
              vx: 0,//Math.random(),
              vy: 0,//Math.random(),
              r: r
            });
          }
        }

      },
      (ctx, e) => {
        // loop
        ctx.fillStyle = 'rgba(255,255,255,.01)';
        ctx.fillRect(0, 0, e.w, e.h);
        
        var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.putImageData(imageData, 0, -5);


        for(var i = 0; i < points.length; i++){
          var p = points[i];

          p.vy += gravity;

          p.x += p.vx;
          p.y += p.vy;

          if(p.x - p.r < 0){
            p.vx *= -1 * friction;
            p.x = p.r;
          }
          if(p.x + p.r > e.w){
            p.vx *= -1 * friction;
            p.x = e.w - p.r;
          }
          if(p.y - p.r < 0){
            p.vy *= -1 * friction;
            p.y = p.r;
          }
          if(p.y + p.r > e.h){
            p.vy *= -1 * friction;
            p.y = e.h - p.r;
          }

          for(var j = i + 1; j < points.length; j++){
            var o = points[j];
                 
            let dx = o.x - p.x;
            let dy = o.y - p.y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            let minDist = o.r + p.r;
            
            if(distance < minDist) {
              let angle = Math.atan2(dy, dx);
              let targetX = p.x + Math.cos(angle) * minDist;
              let targetY = p.y + Math.sin(angle) * minDist;
              let ax = (targetX - o.x) * spring;
              let ay = (targetY - o.y) * spring;
              p.vx -= ax;
              p.vy -= ay;
              o.vx += ax;
              o.vy += ay;
            }
          }
        }
    
        var blur = 1;
        ctx.lineWidth = 10;
        for(var i in points){
          ctx.beginPath();
          ctx.ellipse(points[i].x, points[i].y, points[i].r/2, points[i].r/3, 0, 0, 2 * Math.PI);
          ctx.fillStyle = 'rgba(255,255,255,.5)';
          ctx.strokeStyle = points[i].color;
          ctx.fill();
          ctx.stroke();
        }
      }
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="hello-dots"/>
      </div>
    )
  }
}

export default Sketch;
