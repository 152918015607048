import React, { Component } from 'react'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: "",
      autofix: true,
    }

    this.handleListChange = this.handleListChange.bind(this);
   
  }

  handleListChange(e, retrying, err_counter){
    let input = retrying || e;

    input = input.replace(/\n/g,"");
    input = input.replace(/\r/g,"");

    try{
      if(input !== ""){
        var output_string = JSON.stringify(JSON.parse(input), null, 2);
      } else {
        var output_string = "";
      }

      this.setState({
        input_string: e,
        output_string: output_string
      })
    } catch(err){
      // console.log(err);

      if(!err_counter) err_counter = 0;
      err_counter++;
      if(err_counter < 100){
        
        let err_string = err.toString();
        let quote_count = (input.match(/"/g) || []).length;
        let position = err_string.split(' position ');
        if(position.length > 0){
          position = parseInt(position[1]);
        } else {
          position = false;
        }
        // console.log("position", position, input[position], input[position - 1]);

        // try with some known errors
        if(err_string === 'SyntaxError: Unexpected end of JSON input'){
          if(quote_count % 2 == 1){
            input += '"';
            return this.handleListChange(e, input, err_counter);
          } else {
            input += "}";
            return this.handleListChange(e, input, err_counter);
          }
        }
        if(err_string.indexOf('SyntaxError: Unexpected token') === 0){
          
          if(quote_count % 2 == 1){
            input = input.slice(0, position) + '"' + input.slice(position);
            return this.handleListChange(e, input, err_counter);
          }
        }
        if(err_string.indexOf('SyntaxError: Unexpected token } in JSON at position') === 0){
          if(input[position-1] == ','){
            // extra comma
            input = input.slice(0, position - 1) + input.slice(position);
            return this.handleListChange(e, input, err_counter);
          }
        }

        if(err_string.indexOf('SyntaxError: Unexpected token ] in JSON at position') === 0){
          if(input[position-1] == ','){
            // extra comma
            input = input.slice(0, position - 1) + input.slice(position);
            return this.handleListChange(e, input, err_counter);
          }
        }
      }
    

      this.setState({
        input_string: e,
        output_string: err
      })
    }
  }

  render(){

    return (
      <div id="format-json">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>format json</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter some JSON data here..."
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleListChange(e); }}
                autoFocus={true}
              />
            </div>
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="...and your formatted data will appear here."
                minRows={15}
                lockSize={true}
                multipleRows={true}
                autoUpdate={true}
                value={this.state.output_string}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="flex-split">
                <div className="list-item">
                  <Checkbox value={this.state.autofix} onToggle={(e)=>{ this.setState({autofix: !this.state.autofix}) }} switch={true}/> Try to fix errors
                </div>
              </div>
            </div>
            <div className="col-md-6">
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Sketch;
