import React, { Component } from 'react'
import { push } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import CustomField from '../components/CustomField/CustomField.js'
import SketchManager from '../components/SketchManager/SketchManager.js'
 
var sketches = require('../sketches/_list.js').default;
// sketches.reverse();

class SketchList extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { dispatch } = this.props;
    const state = this.state;

    return <div className="body-stretch">
      <Header/>

      <div className="container sketch-list">
        <div className="header-bumper"/>
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-300">sketchbook</h2>
            <p>
              I give a huge amount of credit to the folks behind Arduino and Processing for making software development immediate and tangible for all skill levels. It was those environments that made me curious enough to teach myself. Sometimes I still create small sketches like I did over a decade ago when I first began programming. 
            </p>
            <p>
              This is a log of some of those sketches using a simple html-canvas graphics library I wrote.
            </p>
          </div>
        </div>
        <hr/>
        
        <div className="row">
          
          {
            sketches.map((s, i)=>{
              return <div className="col-md-12 sketch-thumbnail" key={i}>

                <div className="sketch-thumbnail-number">{sketches.length - i}.</div> 
                <div className="sketch-thumbnail-content">
                  <div><Link to={"/sketchbook/" + s.id} className="sketch-thumbnail-link back-filled">{s.id}</Link></div> 
                  <div className="sketch-thumbnail-date">{s.date}</div>
                  <div className="sketch-thumbnail-description">{s.description}</div>
                </div>
              </div>
            })
          }
        </div>
      </div>
      <Footer/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { 
    // ...
  } = state;

  return {
    // ...
  }
}

export default connect(mapStateToProps)(SketchList);