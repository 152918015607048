import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    LoopingCanvas('cracking-knuckles', 
      (ctx, e) => {
        ctx.fillStyle = 'rgba(0,0,0,1)';
        ctx.fillRect(0, 0, e.w, e.h);
      },
      (ctx, e) => {
        ctx.resetTransform();
        // ctx.clearRect(0, 0, e.w, e.h);

        ctx.fillStyle = 'rgba(100,100,100,.2)';
        ctx.fillRect(0, 0, e.w, e.h);

        
        var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.putImageData(imageData, 0, 20);

        var r = 50;
        var spacing = r * 2;
        var padding_w = e.w % r / 2;
        var padding_h = e.h % r / 2;


        ctx.lineCap = 'round';

        var t = 30;

        var points = [{x: padding_w + r * 2, y: e.h}];
        var reverse_factor = 1;
        var col_size = 0;


        function drawCurvedLine(points){
          var points = JSON.parse(JSON.stringify(points));
          var next_loc;
          ctx.beginPath();
          ctx.moveTo((points[0].x + points[1].x)/2, (points[0].y + points[1].y)/2);
          for(var i = 1; i < points.length; i++){
            next_loc = points[i + 1];
            if(!next_loc) continue;

            var xc = (points[i].x + next_loc.x) / 2;
            var yc = (points[i].y + next_loc.y) / 2;
            ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
          }
        }

        for(var lx = 0; (lx + 1) * spacing < e.w - padding_w * 2; lx++){
          var zig = [];

          for(var ly = 0; (ly + 1) * spacing < e.h - padding_h * 2; ly++){
            var x = (lx + 1) * spacing + padding_w;
            var y = (ly + 1) * spacing + padding_h;
            var theta = e.frameCounter/t + (x + y) / 10;
            zig.push({
              x: x + r * Math.cos(theta),
              y: y + r * Math.sin(theta)
            })
          }

          if(reverse_factor === 1){
            zig.reverse();
          }
          reverse_factor *= -1;

          col_size = zig.length;

          points = points.concat(JSON.parse(JSON.stringify(zig)));
        }


        if(reverse_factor === -1){
          points.splice(points.length - col_size, col_size);
          points.push({x: e.w - padding_w - r * 4, y: e.h});

          for(var i in points){
            points[i].x += r;
          }
        } else {
          points.push({x: e.w - padding_w - r * 2, y: e.h});  
        }

        
        // console.log(points);

        if(points.length > 0){
          ctx.strokeStyle = 'rgba(255,255,255,1)'
          ctx.lineWidth = 5;
          ctx.beginPath();
          ctx.moveTo(points[0].x, points[0].y);
          for(var i = 1; i < points.length; i++){
            ctx.lineTo(points[i].x, points[i].y);
          }

          // drawCurvedLine(points);
          ctx.fillStyle = '#000';
          ctx.fill();
          ctx.stroke();



          for(var i in points){
            // ctx.lineWidth = 1;
            // ctx.beginPath();
            // ctx.arc(points[i].x, points[i].y, 2, 0, 2 * Math.PI);
            // ctx.strokeStyle = '#000';
            // ctx.stroke();
          }
        }
      } 
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="cracking-knuckles" key="cracking-knuckles"/>
      </div>
    )
  }
}

export default Sketch;
