import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    LoopingCanvas('clock-waves', (ctx, e) => {
      ctx.resetTransform();
      ctx.clearRect(0, 0, e.w, e.h);

      var r = 30;
      var spacing = r * 2;
      var padding_w = e.w % spacing / 2;
      var padding_h = e.h % spacing / 2;


      ctx.lineCap = 'round';

      var t = 30;

      for(var ly = 0; (ly + 1) * spacing < e.h - padding_h * 2; ly++){

      var points = [];

        for(var lx = 0; (lx + 1) * spacing < e.w - padding_w * 2; lx++){
          var x = (lx + 1) * spacing + padding_w;
          var y = (ly + 1) * spacing + padding_h;
          var theta = e.frameCounter/t + (x + y) / 250;
          points.push({
            x: x + r * Math.cos(theta),
            y: y + r * Math.sin(theta)
          })

          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.arc(x, y, r, 0, 2 * Math.PI);
          ctx.strokeStyle = 'rgba(0,0,0,.25)'
          ctx.stroke();

        }

        ctx.strokeStyle = 'rgba(0,0,0,1)'
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        for(var i = 1; i < points.length; i++){
          ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.stroke();

        for(var i in points){
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.arc(points[i].x, points[i].y, 2, 0, 2 * Math.PI);
          ctx.strokeStyle = '#000';
          ctx.stroke();
        }
      }

    })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="clock-waves" key="clock-waves"/>
      </div>
    )
  }
}

export default Sketch;
