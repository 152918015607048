import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class Work extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.state = {

    }
  }


  render(){
    const { dispatch, globalReducer } = this.props; 
    const state = this.state;

    return (
      <div className={"work "}>
        
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { globalReducer } = state;

  return {
    globalReducer
  }
}

export default connect(mapStateToProps)(Work);
