const sketches = [
  {
    id: 'debut',
    description: 'a squishy basketball 🏀',
    date: '2019-04-26',
    noHomePage: true
  },
  {
    id: 'uno',
    description: 'blue and red circles in a grid affected by mouse movement',
    date: '2019-04-23',
    noHomePage: true
  },
  {
    id: 'spinning',
    description: 'the word sping is spinning',
    date: '2019-04-23',
    noHomePage: true
  },
  {
    id: 'dot-rotate',
    description: 'randomly adjusting size that impacts speed',
    date: '2019-04-24',
    noHomePage: true
  },
  {
    id: 'pink-blob',
    description: 'drawing a shape through dot-rotate',
    date: '2019-04-24',
    noHomePage: true
  },
  {
    id: 'black-coral',
    description: 'layering and pushing a line through dot-rotate',
    date: '2019-04-24'
  },
  {
    id: 'birds',
    description: 'random movement influenced by neighbors',
    date: '2019-04-25'
  },
  {
    id: 'fire',
    description: 'birds with lines connecting them, colored based on distance',
    date: '2019-04-25',
    noHomePage: true
  },
  {
    id: 'sine-dots',
    description: '🌊 keep it wavy, morgan davy 🌊',
    date: '2019-04-28'
  },
  {
    id: 'noodles',
    description: '🍜',
    date: '2019-04-28'
  },
  {
    id: 'sine-lines',
    description: 'bigger thicker noodles',
    date: '2019-04-28'
  },
  {
    id: 'waves-of-joy',
    description: 'almost joy division',
    date: '2019-04-28'
  },
  {
    id: 'scribble-screen-saver',
    description: 'nineteen ninety something',
    date: '2019-04-29',
    noHomePage: true
  },
  {
    id: 'concentric-squares',
    description: 'turtles all the way down',
    date: '2019-05-01'
  },
  {
    id: 'wobbly-towers',
    description: 'sine waves on the sea floor',
    date: '2019-05-02'
  },
  {
    id: 'sketchy-lines',
    description: 'like shaking out a rug',
    date: '2019-05-02'
  },
  {
    id: 'sketchy-grid',
    description: 'breathing in and out',
    date: '2019-05-02'
  },
  {
    id: 'wall-of-clocks',
    description: 'a recreation not to scale',
    date: '2019-05-04'
  },
  {
    id: 'clock-waves',
    description: 'connecting lines across the hands of clocks',
    date: '2019-05-04'
  },
  {
    id: 'crunchy-connected-clocks',
    description: 'almost gears but not really',
    date: '2019-05-04',
    noHomePage: true
  },
  {
    id: 'cracking-knuckles',
    description: 'forever and ever',
    date: '2019-05-04',
    noHomePage: true
  },
  {
    id: 'hanging-coral',
    description: 'revisiting wobbly-towers but with collision',
    date: '2019-05-05'
  },
  {
    id: 'blurred-zones',
    description: 'same bouncing logic but blurry',
    date: '2019-05-05',
    noHomePage: true
  },
  {
    id: 'confetti',
    description: '🎉',
    date: '2019-05-05',
    noHomePage: true
  },
  {
    id: 'agitated-dots',
    description: 'they just want to go about their business',
    date: '2019-05-15',
    noHomePage: true
  },
  {
    id: 'white-worms',
    description: 'with ant-farm style trails',
    date: '2019-05-15'
  },
  {
    id: 'random-walk',
    description: 'orthogonal random walk contained to the screen',
    date: '2019-05-21'
  },
  {
    id: 'wavy-forget',
    description: 'splicing text and sine waves',
    date: '2019-05-21',
    noHomePage: true
  },
  {
    id: 'glitch-text',
    description: 'slowly dissolving by randomly shifting many small random sections',
    date: '2019-05-23',
    noHomePage: true
  },
  {
    id: 'solar-system',
    description: 'orbiting paths with trails',
    date: '2019-05-23'
  },
  {
    id: 'choppy-hello',
    description: 'so out of sync that its back n*sync',
    date: '2019-05-24'
  },
  {
    id: 'platformer-grid-world',
    description: 'initial simple arrow-key controls for a platformer game',
    date: '2019-05-28',
    noHomePage: true
  },
  {
    id: 'sun2',
    description: 'rotating straight lines in a circle',
    date: '2019-06-07'
  },
  {
    id: 'spiral',
    description: 'from straight line to triangle',
    date: '2019-06-09'
  },
  {
    id: 'platformer-grid-world-2',
    description: 'upgraded platformer engine with land and npc support',
    date: '2019-06-10',
    noHomePage: true
  },
  {
    id: 'subdivide',
    description: 'rectangles arguing for space',
    date: '2019-06-11'
  },
  {
    id: 'subdivide-circles',
    description: 'circles arguing for space with chopped trails',
    date: '2019-06-15'
  },
  {
    id: 'sketchy-cliffs',
    description: 'layered randomly-walking lines',
    date: '2020-10-10'
  },
  {
    id: 'sketchy-cliffs-2',
    description: 'layered randomly-walking lines with alternating directions',
    date: '2020-10-10'
  },
]


export default sketches;