import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){
    var g_theta = 2;
    var r = .05;

    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
      },
      (ctx, e) => {
        // loop
        ctx.clearRect(0, 0, e.w, e.h);
        
        ctx.beginPath();
        ctx.moveTo(e.w/2, e.h/2);
        
        var theta = Math.sin(Math.PI * 1.5 + e.frameCounter / 600) + g_theta;
        r = 5 / theta;

        for(var i = 0; i < 100; i++){
          ctx.lineTo(
            e.w / 2 + (i * (r)) * Math.cos(i * Math.PI * 2 / theta), 
            e.h / 2 + (i * (r)) * Math.sin(i * Math.PI * 2 / theta)
          )
        }    
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#fff';
        ctx.fill();
        ctx.stroke();
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
