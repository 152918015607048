import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    var points = [];
    let spring = 0.00003;
    let gravity = 0.00;
    let friction = 0.9;

    let a = 0;
    let r = 20;

    LoopingCanvas('fungi', 
      (ctx, e) => {
        // optional initialize
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, e.w, e.h);

        ctx.lineCap = 'round';

        var t = 30;

        for(var i = 0; i < 5; i++){
          points.push({
            x: e.w/2 + (Math.random() - .5) * 2,
            y: e.h/2 + (Math.random() - .5) * 2,
            vx: 0,
            vy: 0,
            r: r * Math.random(),
            touches: 0,
            color: parseInt(Math.random() * 360),
            splitCounter: 100 + parseInt(100 * Math.random())
          });
        }

      },
      (ctx, e) => {
        // loop
        ctx.fillStyle = 'rgba(255,255,255,.01)';
        ctx.fillRect(0, 0, e.w, e.h);
        
        var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.putImageData(imageData, 0, 1);

        for(var i = 0; i < points.length; i++){
          var p = points[i];
          if(!p) break;
          if(p.splitCounter == 0 && p.touches < 400){
            console.log('split');
            for(var j = 0; j < 10; j++){
              points.push({
                x: points[i].x + (Math.random() - .5) * 2,
                y: points[i].y + (Math.random() - .5) * 2,
                vx: 0,
                vy: 0,
                r: r * Math.random(),
                touches: 0,
                color: parseInt(p.color + 30 * (Math.random() - .5)),
                splitCounter: 100 + parseInt(100 * Math.random())
              });
            }
          }

          if(p.splitCounter < 0){
            points.splice(i, 1);
            i--;
          }

          p.vy += gravity;

          p.x += p.vx;
          p.y += p.vy;

          if(p.x - p.r < 0){
            p.vx *= -1 * friction;
            p.x = p.r;
          }
          if(p.x + p.r > e.w){
            p.vx *= -1 * friction;
            p.x = e.w - p.r;
          }
          if(p.y - p.r < 0){
            p.vy *= -1 * friction;
            p.y = p.r;
          }
          if(p.y + p.r > e.h){
            p.vy *= -1 * friction;
            p.y = e.h - p.r;
          }

          for(var j = i + 1; j < points.length; j++){
            var o = points[j];
            if(o.splitCounter < 0) continue;
                 
            let dx = o.x - p.x;
            let dy = o.y - p.y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            let minDist = (o.r + p.r) * 3;
            
            if(distance < minDist) {
              p.touches++;
              let angle = Math.atan2(dy, dx);
              let targetX = p.x + Math.cos(angle) * minDist;
              let targetY = p.y + Math.sin(angle) * minDist;
              let ax = (targetX - o.x) * spring;
              let ay = (targetY - o.y) * spring;
              p.vx -= ax;
              p.vy -= ay;
              o.vx += ax;
              o.vy += ay;


              if(o.splitCounter <= 70 && p.splitCounter <= 70){
                o.vx = (o.vx + p.vx) / 2;
                o.vy = (o.vy + p.vy) / 2;
                points.splice(i, 1);
                i--;
                break;
              }
            }
          }

          p.splitCounter--;
        }
    
        var blur = 1;
        ctx.lineWidth = 1;
        for(var i in points){
          ctx.beginPath();
          ctx.ellipse(points[i].x, points[i].y, points[i].r/2, points[i].r/2, 0, 0, 2 * Math.PI);
          ctx.strokeStyle = '#000'
          ctx.fillStyle = 'hsl('+ points[i].color +',100%,50%)';
          ctx.fill();
          ctx.stroke();
        }
      }
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="fungi"/>
      </div>
    )
  }
}

export default Sketch;
