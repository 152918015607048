import React, { Component } from 'react'

import CustomField from '../components/CustomField/CustomField.js';
import Button from '../components/Button/Button.js';
import Checkbox from '../components/Checkbox/Checkbox.js';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      input_string: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOutputChange = this.handleOutputChange.bind(this);
  }

  handleInputChange(e){
    let input = e;
    try{
      let output_string = window.btoa(input);
      this.setState({
        input_string: e,
        output_string: output_string
      })
    } catch(err){
      this.setState({
        input_string: e,
        output_string: err.toString()
      })
    }
  }

  handleOutputChange(e){
    let input = e;
    try{
      let output_string = window.atob(input);
      this.setState({
        output_string: e,
        input_string: output_string
      })
    } catch(err){
      this.setState({
        output_string: e,
        input_string: err.toString()
      })
    }
  }

  render(){

    return (
      <div id="base64">
        <div className="header-bumper"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>base64</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="enter raw data here to encode it..."
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.input_string}
                onChangeEvent={(e) => { this.handleInputChange(e); }}
                autoFocus={true}
              />
            </div>
            <div className="col-md-6">
              <CustomField
                type="text"
                name="input-list"
                placeholder="...or enter encoded data here to decode it."
                minRows={15}
                multipleRows={true}
                lockSize={true}
                autoUpdate={true}
                value={this.state.output_string}
                onChangeEvent={(e) => { this.handleOutputChange(e); }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Sketch;
