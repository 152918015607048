import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    // var data = [0,0,65,65,10,10,10,10,12.83,12.83,12.83,12.83,12.83,12.83,3.33,3.3,3.3,3.3,3.3,3.3,6.42,6.42,6.42,6.42,6.42,6.42,6.42,6.42,6.42,6.42,6.42,6.42,10,10,10,10,2,2,2,2,2,2,12.1833,12.1833,12.1833,12.1833,12.1833,12.1833];
    var data = [0,0,130,0,0,40,0,0,0,0,0,0,0,77,0,0,0,0,0,20,0,0,0,0,0,77,0,0,0,0,0,0,0,40,0,0,0,0,10,0,0,0,0,0,73.1,0,0,0];
    LoopingCanvas('sketch-id', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
        var position = 0;
        var left_weight = Number.MIN_VALUE;
        var right_weight = Number.MAX_VALUE;

        var min_dif = Number.MAX_VALUE;
        var min_dif_index = 0;
        var difs = [];

        for(var i = 0; i < data.length; i++){

          // weight_left
          var lw = 0;
          for(var j = 0; j < i; j++){
            lw += data[j] * (i - j);
          }


          // weight_right
          var rw = 0;
          for(var j = i + 1; j < data.length; j++){
            rw += data[j] * (j - i);
          }

          var dif = Math.abs(lw - rw);
          if(dif < min_dif){
            min_dif = dif;
            min_dif_index = i;
          }
          difs.push(rw - lw);
        }
        console.log(min_dif, min_dif_index, data.length, difs);
      },
      (ctx, e) => {
        // loop
        ctx.clearRect(0, 0, e.w, e.h);
        
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketch-id"/>
      </div>
    )
  }
}

export default Sketch;
