import React, { Component } from 'react'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount(){

    let points = [];

    let last_change = 0;
    let last_clear = 0;
    let frames_between = 1;
    let frames_between_clears = 30 * 3;
    let a1 = 0; //(Math.PI * 2) * Math.random();
    let a2 = a1 - Math.PI;
    let factor = 1;

    const randomAngle = () => {
      return Math.PI * 2 * (Math.random() - .5) * 30/360;
      
    }


    LoopingCanvas('sketchy-cliffs', 
      (ctx, e) => {
        // optional initialize
        ctx.clearRect(0, 0, e.w, e.h);
        
        points.push({
          x: e.w / 2,
          y: e.h / 2
        })     
      },
      (ctx, e) => {
        // loop


        if(e.frameCounter - last_clear > frames_between_clears){
          last_clear = e.frameCounter
          points = [];
          points.push({
            x: e.w / 2,
            y: e.h / 2
          })     

          a1 = 0;
          a2 = a1 - Math.PI;
          factor *= -1
        }
        
        if(e.frameCounter - last_change > frames_between){
          last_change = e.frameCounter;
          let p = points[points.length - 1];
          a1 = a1 + randomAngle();
          points.push({
            x: p.x + 50 * Math.random() * Math.cos(a1),
            y: p.y + 50 * Math.random() * Math.sin(a1)
          })

          p = points[0];
          a2 = a2 + randomAngle();
          points.unshift({
            x: p.x + 50 * Math.random() * Math.cos(a2),
            y: p.y + 50 * Math.random() * Math.sin(a2)
          })
        }




        const sketchyLine = (point_array, sketchyness) => {

          function sketch(){
            return sketchyness * (Math.random() - .5) /2;
          }

          // for(var n in numLines){
            for(var i = 0; i < sketchyness / 20; i++){
              ctx.beginPath();
              for(var j in point_array){
                if(j == 0) ctx.moveTo(point_array[j].x + sketch(), point_array[j].y + sketch());  
                if(j > 0) ctx.lineTo(point_array[j].x + sketch(), point_array[j].y + sketch());  
              }
              if(factor > 0){
                ctx.strokeStyle = '#000';
              } else {
                ctx.strokeStyle = '#fff';
              }
              ctx.lineWidth = 1;
              ctx.stroke();
            }
          // }
        }

        // ctx.fillStyle = 'rgba(255,255,255,.01)';
        // ctx.fillRect(0, 0, e.w, e.h);

        ctx.strokeStyle = "#fff";
        ctx.lineWidth = 10;
        ctx.strokeRect(0,0,e.w,e.h);

        var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.putImageData(imageData, 0, 3 * factor);
       

        sketchyLine(points, 30);
      }
    )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id="sketchy-cliffs"/>
      </div>
    )
  }
}

export default Sketch;
